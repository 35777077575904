import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { X, Crown, Medal, Trophy, Award, Star, Building2, Target, Sparkles, RefreshCw } from 'lucide-react'
import { collection, query, orderBy, limit, getDocs, where, Timestamp, DocumentData, getDoc, doc } from 'firebase/firestore'
import { db, auth } from '../firebase'
import { Score, Tournament } from '../types'
import { toast } from 'react-hot-toast'
import Scoreboard from './Scoreboard'

interface LeaderboardProps {
  isOpen: boolean
  onClose: () => void
}

const SCORES_LIMIT = 20;

// Add type safety for score data
const processScoreData = (doc: any, scoreData: any, userData: any | null): Score => {
  const now = Timestamp.now();
  return {
    id: doc.id,
    userId: scoreData.userId,
    displayName: userData?.displayName ?? scoreData.displayName ?? 'Anonymous',
    photoURL: userData?.photoURL ?? scoreData.photoURL ?? null,
    totalGames: scoreData.totalGames || 0,
    gamesWon: scoreData.gamesWon || 0,
    gamesLost: scoreData.gamesLost || 0,
    averageGuesses: scoreData.averageGuesses || 0,
    lastPlayed: scoreData.lastPlayed instanceof Timestamp ? scoreData.lastPlayed : now,
    currentStreak: scoreData.currentStreak || 0,
    bestStreak: scoreData.bestStreak || 0,
    company: userData?.company ?? scoreData.company ?? 'Unknown',
    department: userData?.department ?? scoreData.department ?? 'Unknown',
    createdAt: scoreData.createdAt instanceof Timestamp ? scoreData.createdAt : now,
    updatedAt: scoreData.updatedAt instanceof Timestamp ? scoreData.updatedAt : now
  };
};

// Add index error handling
const handleQueryError = (error: any) => {
  if (error.code === 'failed-precondition' && error.message.includes('index')) {
    const indexMatch = error.message.match(/https:\/\/console\.firebase\.google\.com[^\s]+/);
    const indexUrl = indexMatch ? indexMatch[0] : null;
    
    console.error('Missing index. Create it here:', indexUrl);
    return 'Leaderboard is being optimized. Please try again in a few minutes.';
  }
  return 'Failed to load leaderboard. Please try again.';
};

export function Leaderboard({ isOpen, onClose }: LeaderboardProps) {
  const [scores, setScores] = useState<Score[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [timeFrame, setTimeFrame] = useState<'all' | 'month' | 'week'>('all')
  const [lastRefresh, setLastRefresh] = useState<Date>(new Date());
  const [activeTournament, setActiveTournament] = useState<Tournament | null>(null);

  const handleRefresh = () => {
    setLastRefresh(new Date());
  };

  useEffect(() => {
    const fetchScores = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const scoresRef = collection(db, 'scores');
        const q = query(
          scoresRef, 
          orderBy('totalGames', 'desc'), 
          limit(100)
        );
        
        const snapshot = await getDocs(q);
        const userIds = [...new Set(snapshot.docs
          .map(doc => doc.data().userId)
          .filter(Boolean)
        )];

        // Fetch user data directly from users collection using userId
        const userMap = new Map();
        for (const userId of userIds) {
          try {
            const userDoc = await getDoc(doc(db, 'users', userId));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              // Store the complete user data
              userMap.set(userId, {
                ...userData,
                company: userData.company || null,
                department: userData.department || null
              });
            }
          } catch (err) {
            console.error(`Error fetching user ${userId}:`, err);
          }
        }
        
        const scoresData: Score[] = snapshot.docs
          .map(doc => {
            const data = doc.data();
            const user = userMap.get(data.userId);
            return {
              id: doc.id,
              userId: data.userId,
              displayName: user?.displayName || data.displayName || 'Anonymous',
              photoURL: user?.photoURL || null,
              totalGames: data.totalGames || 0,
              gamesWon: data.gamesWon || 0,
              gamesLost: data.gamesLost || 0,
              averageGuesses: data.averageGuesses || 0,
              lastPlayed: data.lastPlayed || Timestamp.now(),
              currentStreak: data.currentStreak || 0,
              bestStreak: data.bestStreak || 0,
              company: user?.company || null,
              department: user?.department || null,
              createdAt: data.createdAt || Timestamp.now(),
              updatedAt: data.updatedAt || Timestamp.now()
            };
          })
          // Only show players who have played games AND won at least one game
          .filter(score => score.totalGames > 0 && score.gamesWon > 0)
          // Sort by win rate, then by total games, then by best streak
          .sort((a, b) => {
            const aWinRate = (a.gamesWon / a.totalGames) * 100;
            const bWinRate = (b.gamesWon / b.totalGames) * 100;
            
            if (bWinRate !== aWinRate) {
              return bWinRate - aWinRate; // Sort by win rate first
            }
            
            if (b.totalGames !== a.totalGames) {
              return b.totalGames - a.totalGames; // Then by total games
            }
            
            return b.bestStreak - a.bestStreak; // Finally by best streak
          });

        setScores(scoresData);
      } catch (error) {
        console.error('Error fetching scores:', error);
        setError('Failed to load leaderboard');
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchScores()
    }
  }, [isOpen, timeFrame, lastRefresh])

  useEffect(() => {
    const fetchActiveTournament = async () => {
      try {
        const tournamentsRef = collection(db, 'tournaments');
        const now = Timestamp.now();
        
        // First query for active tournaments
        const q = query(
          tournamentsRef,
          where('startDate', '<=', now),
          orderBy('startDate', 'desc'),
          limit(1)
        );
        
        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
          const doc = snapshot.docs[0];
          const data = doc.data();
          
          // Check if tournament is still active
          if (data.endDate.toDate() >= now.toDate()) {
            const tournamentData = {
              ...data,
              id: doc.id
            } as Tournament;
            setActiveTournament(tournamentData);
          }
        }
      } catch (error) {
        console.error('Error fetching active tournament:', error);
        if (error instanceof Error && error.message.includes('requires an index')) {
          toast.error('Tournament data is being indexed. Please try again in a few minutes.');
        } else {
          toast.error('Failed to load tournament data');
        }
      }
    };

    if (isOpen) {
      fetchActiveTournament();
    }
  }, [isOpen]);

  if (!isOpen) return null

  const getRankIcon = (index: number) => {
    switch (index) {
      case 0:
        return <Crown className="text-yellow-400" size={24} />
      case 1:
        return <Medal className="text-gray-400" size={24} />
      case 2:
        return <Medal className="text-amber-600" size={24} />
      default:
        return <Star className="text-emerald-400" size={24} />
    }
  }

  const LoadingState = () => (
    <div className="flex flex-col items-center justify-center py-12">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-500 mb-4" />
      <p className="text-gray-400">Loading leaderboard...</p>
    </div>
  );

  const ErrorState = ({ message }: { message: string }) => (
    <div className="flex flex-col items-center justify-center py-12">
      <div className="text-red-400 mb-2">⚠️</div>
      <p className="text-red-400">{message}</p>
      <button 
        onClick={() => window.location.reload()} 
        className="mt-4 px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-lg text-white transition-colors"
      >
        Try Again
      </button>
    </div>
  );

  return createPortal(
    <div className="fixed inset-0 bg-black/90 backdrop-blur-sm flex items-center justify-center p-4 z-[100]">
      <div className="bg-gray-800 rounded-lg p-8 max-w-4xl w-full border-2 border-emerald-500 shadow-[0_0_15px_rgba(16,185,129,0.3)] animate-fade-in overflow-y-auto max-h-[90vh]">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-white flex items-center gap-2">
            <Trophy className="text-emerald-500" />
            Leaderboard
          </h2>
          <div className="flex items-center gap-2">
            <button
              onClick={handleRefresh}
              className="p-2 hover:bg-gray-700 rounded-full transition-colors"
              title="Refresh"
            >
              <RefreshCw size={20} className="text-gray-400 hover:text-white" />
            </button>
            <button 
              onClick={onClose}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <X size={24} />
            </button>
          </div>
        </div>

        {/* Time Frame Selector */}
        <div className="flex gap-2 mb-6">
          <button
            onClick={() => setTimeFrame('all')}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
              timeFrame === 'all'
                ? 'bg-emerald-500 text-white'
                : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            }`}
          >
            All Time
          </button>
          <button
            onClick={() => setTimeFrame('month')}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
              timeFrame === 'month'
                ? 'bg-emerald-500 text-white'
                : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            }`}
          >
            This Month
          </button>
          <button
            onClick={() => setTimeFrame('week')}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
              timeFrame === 'week'
                ? 'bg-emerald-500 text-white'
                : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            }`}
          >
            This Week
          </button>
        </div>

        {isLoading ? (
          <LoadingState />
        ) : error ? (
          <ErrorState message={error} />
        ) : scores.length === 0 ? (
          <div className="text-center text-gray-400 py-12">
            No scores yet. Be the first to play!
          </div>
        ) : (
          <div className="space-y-8">
            {/* Regular Gameplay Leaderboard */}
            {scores.length > 0 && (
              <Scoreboard
                data={scores.map(score => ({
                  name: score.displayName,
                  score: Math.round((score.gamesWon / score.totalGames) * 100),
                  photoURL: score.photoURL,
                  isCurrentUser: score.userId === auth.currentUser?.uid,
                  metadata: {
                    company: score.company,
                    department: score.department,
                    winRate: ((score.gamesWon / score.totalGames) * 100).toFixed(1),
                    bestStreak: score.bestStreak,
                    gamesWon: score.gamesWon,
                    totalGames: score.totalGames
                  }
                }))}
                title="Global Leaderboard"
              />
            )}

            {/* Active Tournament Section */}
            {activeTournament && (
              <Scoreboard
                data={activeTournament.teams.map(team => ({
                  name: team.name,
                  score: team.score || 0,
                  isCurrentUser: team.members.some(m => m.userId === auth.currentUser?.uid),
                  metadata: {
                    memberCount: team.members.length,
                    rank: team.rank,
                    gamesPlayed: team.gamesPlayed
                  }
                }))}
                title={`Active Tournament: ${activeTournament.name}`}
                tournament={activeTournament}
              />
            )}
          </div>
        )}
      </div>
    </div>,
    document.body
  )
}

export default Leaderboard
