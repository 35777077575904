import React, { useState, useEffect } from 'react';
import { Receipt, Menu as MenuIcon, HelpCircle, ArrowRight, CheckCircle, Target, Trophy, Sparkles, Building2, Scale, Shield } from 'lucide-react';
import { Toaster } from 'react-hot-toast';
import Grid from './components/Grid';
import Keyboard from './components/Keyboard';
import GameOver from './components/GameOver';
import FlyoutMenu from './components/FlyoutMenu';
import { useStore } from './store';
import { ErrorBoundary } from './components/ErrorBoundary';
import { SignInPrompt } from './components/SignInPrompt';
import { Rules } from './components/Rules';
import { gameSounds } from './utils/sounds';
import { auth } from './firebase';
import AuthDialog from './components/AuthDialog';
import { useMediaQuery } from './hooks/useMediaQuery'
import TermsOfService from './components/TermsOfService'
import PrivacyPolicy from './components/PrivacyPolicy'
import { TournamentAlert } from './components/TournamentAlert'
import Leaderboard from './components/Leaderboard';
import StatsDialog from './components/StatsDialog';
import { Tournament } from './types';

function WelcomeBanner() {
  const [currentFeature, setCurrentFeature] = useState(0)
  const [showAuth, setShowAuth] = useState(false)
  const [isSignUp, setIsSignUp] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user)
    })
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentFeature((prev) => (prev + 1) % features.length)
    }, 5000)

    return () => clearInterval(timer)
  }, [])

  const features = [
    {
      icon: <Target className="w-10 h-10 text-white" />,
      title: "Welcome to Taxle",
      description: "The daily word challenge for tax professionals.",
      gradient: "from-emerald-500 to-emerald-600",
      bgGradient: "from-emerald-600/20 via-emerald-500/10 to-emerald-600/20"
    },
    {
      icon: <Trophy className="w-10 h-10 text-white" />,
      title: "Compete & Learn",
      description: "Challenge yourself and compete with peers.",
      gradient: "from-blue-500 to-indigo-600",
      bgGradient: "from-blue-600/20 via-blue-500/10 to-blue-600/20"
    },
    {
      icon: <CheckCircle className="w-10 h-10 text-white" />,
      title: "Track Progress",
      description: "Build your skills, one word at a time.",
      gradient: "from-purple-500 to-pink-600",
      bgGradient: "from-purple-600/20 via-purple-500/10 to-purple-600/20"
    }
  ]

  const handleGetStarted = () => {
    setShowAuth(true)
    setIsSignUp(true)
  }

  if (isAuthenticated) return null

  return (
    <div className="relative overflow-hidden border-b border-gray-800/50">
      <div className="absolute inset-0 bg-gradient-to-r from-gray-900 via-black to-gray-900" />
      <div className="absolute inset-0 bg-gradient-to-r opacity-20"
        style={{
          backgroundImage: `linear-gradient(to right, ${features[currentFeature].bgGradient})`
        }}
      />
      
      <div className="relative max-w-7xl mx-auto px-4 py-12">
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
          <div className="flex-1 space-y-6">
            <div className="flex items-center gap-6 animate-fade-in">
              <div className={`p-4 rounded-2xl bg-gradient-to-br ${features[currentFeature].gradient} shadow-xl`}>
                {features[currentFeature].icon}
              </div>
              <div>
                <h2 className="text-3xl md:text-4xl font-bold text-white mb-2">
                  {features[currentFeature].title}
                </h2>
                <p className="text-xl text-gray-200 leading-relaxed">
                  {features[currentFeature].description}
                </p>
              </div>
            </div>

            <button 
              onClick={handleGetStarted}
              className="group relative inline-flex items-center gap-2 px-8 py-4 bg-white text-gray-900 rounded-full text-lg font-semibold shadow-xl hover:shadow-2xl hover:scale-105 transition-all duration-300"
            >
              <span>Get Started Free</span>
              <ArrowRight size={20} className="group-hover:translate-x-1 transition-transform" />
              <div className="absolute inset-0 rounded-full bg-gradient-to-r from-emerald-200 to-emerald-300 opacity-0 group-hover:opacity-100 transition-opacity -z-10" />
            </button>
          </div>
        </div>

        {/* Progress Indicators */}
        <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2 flex gap-3">
          {features.map((_, index) => (
            <div
              key={index}
              className={`
                h-1.5 rounded-full transition-all duration-500 
                ${index === currentFeature ? 'w-8 bg-white' : 'w-1.5 bg-gray-600'}
              `}
            />
          ))}
        </div>
      </div>

      {/* Auth Dialog */}
      <AuthDialog 
        isOpen={showAuth} 
        onClose={() => setShowAuth(false)}
        initialMode="signup"
      />
    </div>
  )
}

function SponsorSection() {
  const [showTOS, setShowTOS] = useState(false)
  const [showPrivacy, setShowPrivacy] = useState(false)
  const isMobile = useMediaQuery('(max-width: 768px)')
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user)
    })
    return () => unsubscribe()
  }, [])

  if (isMobile || isAuthenticated) return null

  return (
    <footer className="mt-auto py-8 border-t border-gray-800">
      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-8">
          <h3 className="text-lg font-semibold text-transparent bg-clip-text bg-gradient-to-r from-emerald-400 to-emerald-600 inline-flex items-center gap-2">
            <Sparkles className="w-5 h-5" />
            Proudly Supported By
          </h3>
          <p className="text-sm text-gray-400 mt-1">Industry leaders who support tax education</p>
        </div>

        <div className="flex justify-center">
          <a 
            href="https://engineeredtaxservices.com" 
            target="_blank" 
            rel="noopener noreferrer"
            className="group relative overflow-hidden rounded-xl bg-gradient-to-br from-gray-800 to-gray-900 p-1 w-full max-w-sm"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-emerald-500 to-emerald-700 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
            
            <div className="relative bg-gray-900 rounded-lg p-4 transition-transform duration-300 group-hover:transform group-hover:scale-[0.98]">
              <div className="aspect-video relative overflow-hidden rounded-md bg-gray-800 flex items-center justify-center">
                <img 
                  src="/images/ETS_horizontal.png"
                  alt="Engineered Tax Services"
                  className="w-full h-full object-contain p-4 transform transition-all duration-300 group-hover:scale-105"
                  onError={(e) => {
                    console.error('Error loading image:', e);
                    e.currentTarget.style.display = 'none';
                  }}
                />
                <div className="absolute inset-0 bg-gradient-to-t from-emerald-500/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <div className="absolute bottom-0 left-0 right-0 p-4 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300">
                  <span className="text-xs text-white/90 bg-emerald-500/90 px-2 py-1 rounded-full">
                    Visit Website
                  </span>
                </div>
              </div>
            </div>
          </a>
        </div>

        {/* Footer Links */}
        <div className="mt-8 flex items-center justify-center gap-6 text-sm text-gray-400">
          <a 
            href="mailto:taxleapp@gmail.com" 
            className="inline-flex items-center gap-2 hover:text-emerald-300 transition-colors"
          >
            <Sparkles className="w-4 h-4" />
            Become a Sponsor
          </a>
          <span className="text-gray-600">|</span>
          <button
            onClick={() => setShowTOS(true)}
            className="inline-flex items-center gap-2 hover:text-emerald-300 transition-colors"
          >
            <Scale className="w-4 h-4" />
            Terms of Service
          </button>
          <span className="text-gray-600">|</span>
          <button
            onClick={() => setShowPrivacy(true)}
            className="inline-flex items-center gap-2 hover:text-emerald-300 transition-colors"
          >
            <Shield className="w-4 h-4" />
            Privacy Policy
          </button>
          <span className="text-gray-600">|</span>
          <span className="text-gray-500">
            © {new Date().getFullYear()} Taxle. All rights reserved.
          </span>
        </div>
      </div>

      <TermsOfService isOpen={showTOS} onClose={() => setShowTOS(false)} />
      <PrivacyPolicy isOpen={showPrivacy} onClose={() => setShowPrivacy(false)} />
    </footer>
  )
}

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { 
    showLeaderboard, 
    showStats, 
    setShowLeaderboard, 
    setShowStats,
    currentVariant, 
    showSignInPrompt, 
    setShowSignInPrompt 
  } = useStore();
  const [currentTournament, setCurrentTournament] = useState<Tournament | undefined>(undefined);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsAuthenticated(!!user);
      if (user) {
        gameSounds.initializeWithUserPreference();
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme')
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    
    if (savedTheme === 'dark' || (!savedTheme && prefersDark)) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [])

  return (
    <ErrorBoundary>
      <div className="min-h-screen flex flex-col bg-gradient-to-b from-gray-900 to-gray-800 dark:from-black dark:to-gray-900 transition-colors duration-200">
        <Toaster 
          position="top-center" 
          toastOptions={{ 
            style: { 
              zIndex: 100,
              background: 'var(--toast-bg)',
              color: 'var(--toast-text)',
            },
            className: 'dark:bg-gray-800 dark:text-white'
          }} 
        />
        
        {/* Modern Apple-style Header */}
        <header className="sticky top-0 z-30 backdrop-blur-lg bg-gray-900/80 dark:bg-black/80 border-b border-gray-800 dark:border-gray-900 transition-colors duration-200">
          <div className="max-w-7xl mx-auto">
            <div className="px-4 py-3 flex items-center justify-between">
              <div className="flex items-center gap-3">
                <Receipt className="w-8 h-8 text-emerald-500" />
                <div>
                  <h1 className="text-2xl font-bold text-white tracking-tight">
                    Taxle
                  </h1>
                  {currentVariant && (
                    <p className="text-sm text-emerald-400">
                      Playing: {currentVariant.name}
                    </p>
                  )}
                </div>
              </div>
              
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setShowRules(true)}
                  className="text-gray-300 hover:text-emerald-400 transition-colors flex items-center gap-1"
                >
                  <HelpCircle size={20} />
                  <span className="text-sm hidden sm:inline">How to Play</span>
                </button>
                <button
                  onClick={() => setIsMenuOpen(true)}
                  className="p-2 text-white hover:text-emerald-400 transition-colors group"
                  aria-label="Open menu"
                >
                  <MenuIcon 
                    size={24} 
                    className="transform transition-transform group-hover:scale-110" 
                  />
                </button>
              </div>
            </div>
          </div>
        </header>

        {/* Add WelcomeBanner here */}
        <WelcomeBanner />

        {/* Apple-style Flyout Menu */}
        <FlyoutMenu 
          isOpen={isMenuOpen} 
          onClose={() => setIsMenuOpen(false)} 
        />

        {/* Game Content */}
        <main className="relative z-10 max-w-4xl mx-auto px-4 py-8">
          <div className="flex flex-col items-center gap-8 game-area">
            <div className="w-full max-w-sm mx-auto">
              <Grid />
            </div>
            <div className="w-full max-w-lg mx-auto">
              <Keyboard />
            </div>
          </div>
        </main>

        {/* Add Sponsor Section */}
        <SponsorSection />

        <GameOver />
        
        <SignInPrompt 
          isOpen={showSignInPrompt} 
          onClose={() => setShowSignInPrompt(false)} 
        />

        <Rules isOpen={showRules} onClose={() => setShowRules(false)} />

        {currentTournament && (
          <TournamentAlert
            tournament={currentTournament}
            onClose={() => setCurrentTournament(undefined)}
          />
        )}

        <ErrorBoundary>
          <Leaderboard 
            isOpen={showLeaderboard} 
            onClose={() => setShowLeaderboard(false)} 
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <StatsDialog 
            isOpen={showStats} 
            onClose={() => setShowStats(false)} 
          />
        </ErrorBoundary>
      </div>
    </ErrorBoundary>
  );
}

export default App;