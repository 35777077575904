import React, { useState, useEffect } from 'react';
import { Trophy, Users, Calendar, Award, Clock, Target, Plus, Trash2 } from 'lucide-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Timestamp } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import { TournamentFormData } from '../../types';

interface TournamentFormProps {
  onSubmit: (data: TournamentFormData) => void;
  onCancel?: () => void;
  initialData?: Partial<TournamentFormData>;
  isEditing?: boolean;
}

const defaultFormData: TournamentFormData = {
  name: '',
  description: '',
  startDate: new Date(),
  endDate: new Date(),
  registrationDeadline: new Date(),
  maxTeams: 10,
  teamSize: 5,
  prizes: {
    first: '',
    second: '',
    third: ''
  },
  rules: [],
  teamNames: [],
  companyOnly: false,
  notificationsEnabled: false
};

// Add date validation
const validateDates = (dates: { 
  registrationDeadline: Date, 
  startDate: Date, 
  endDate: Date 
}): boolean => {
  const now = new Date();
  const { registrationDeadline, startDate, endDate } = dates;

  if (registrationDeadline < now) {
    toast.error('Registration deadline cannot be in the past');
    return false;
  }

  if (startDate < registrationDeadline) {
    toast.error('Start date must be after registration deadline');
    return false;
  }

  if (endDate < startDate) {
    toast.error('End date must be after start date');
    return false;
  }

  return true;
};

export function TournamentForm({ onSubmit, onCancel, initialData, isEditing }: TournamentFormProps) {
  const [formData, setFormData] = useState<TournamentFormData>({
    ...defaultFormData,
    ...initialData
  });

  const handleChange = (field: keyof TournamentFormData, value: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handlePrizeChange = (field: keyof TournamentFormData['prizes'], value: string) => {
    setFormData(prev => ({
      ...prev,
      prizes: {
        ...prev.prizes,
        [field]: value
      }
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateDates({
      registrationDeadline: formData.registrationDeadline,
      startDate: formData.startDate,
      endDate: formData.endDate
    })) {
      return;
    }
    
    onSubmit(formData);
  };

  const addRule = () => {
    setFormData(prev => ({
      ...prev,
      rules: [...(prev.rules || []), '']
    }));
  };

  const updateRule = (index: number, value: string) => {
    setFormData(prev => ({
      ...prev,
      rules: prev.rules.map((rule, i) => i === index ? value : rule)
    }));
  };

  const removeRule = (index: number) => {
    setFormData(prev => ({
      ...prev,
      rules: prev.rules.filter((_, i) => i !== index)
    }));
  };

  const addTeam = () => {
    setFormData(prev => ({
      ...prev,
      teamNames: [...(prev.teamNames || []), `Team ${(prev.teamNames || []).length + 1}`]
    }));
  };

  // Update input styles to support dark mode consistently
  const inputClasses = "w-full px-4 py-2 border rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600";
  const labelClasses = "block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1";

  // Update the date handlers to handle null
  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setFormData(prev => ({ ...prev, startDate: date }));
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setFormData(prev => ({ ...prev, endDate: date }));
    }
  };

  const handleRegistrationDeadlineChange = (date: Date | null) => {
    if (date) {
      setFormData(prev => ({ ...prev, registrationDeadline: date }));
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid gap-6">
        {/* Tournament Details */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-surface-200">
            Tournament Details
          </h3>
          
          <div>
            <label className="block text-lg font-medium text-surface-200">
              Tournament Name
            </label>
            <input
              type="text"
              placeholder="Enter tournament name"
              value={formData.name}
              onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className={inputClasses}
              required
            />
          </div>
          
          <div>
            <label className={labelClasses}>Description</label>
            <textarea
              placeholder="Enter tournament description"
              value={formData.description}
              onChange={e => setFormData(prev => ({ ...prev, description: e.target.value }))}
              className={inputClasses}
              rows={3}
              required
            />
          </div>
        </div>

        {/* Dates Section */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-surface-200">
            Tournament Schedule
          </h3>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-lg font-medium text-surface-200">
                Registration Deadline
              </label>
              <DatePicker
                selected={formData.registrationDeadline}
                onChange={handleRegistrationDeadlineChange}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                className="w-full px-4 py-2 bg-surface-800 border border-surface-700 rounded-lg text-white"
                minDate={new Date()}
                required
              />
            </div>
            <div>
              <label className="block text-lg font-medium text-surface-200">
                Start Date
              </label>
              <DatePicker
                selected={formData.startDate}
                onChange={handleStartDateChange}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                className="w-full px-4 py-2 bg-surface-800 border border-surface-700 rounded-lg text-white"
                minDate={formData.registrationDeadline}
                required
              />
            </div>
            <div>
              <label className="block text-lg font-medium text-surface-200">
                End Date
              </label>
              <DatePicker
                selected={formData.endDate}
                onChange={handleEndDateChange}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                className="w-full px-4 py-2 bg-surface-800 border border-surface-700 rounded-lg text-white"
                minDate={formData.startDate}
                required
              />
            </div>
          </div>
        </div>

        {/* Team Configuration */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-surface-200">
            Team Configuration
          </h3>
          
          <div className="grid grid-cols-2 gap-6">
            <div className="space-y-2">
              <label className="block text-lg font-medium text-surface-200">
                Maximum Teams
              </label>
              <input
                type="number"
                value={formData.maxTeams}
                onChange={(e) => handleChange('maxTeams', parseInt(e.target.value))}
                className="w-full px-4 py-2 bg-surface-800 border border-surface-700 rounded-lg text-white"
              />
            </div>
            <div className="space-y-2">
              <label className="block text-lg font-medium text-surface-200">
                Team Size
              </label>
              <input
                type="number"
                value={formData.teamSize}
                onChange={(e) => handleChange('teamSize', parseInt(e.target.value))}
                className="w-full px-4 py-2 bg-surface-800 border border-surface-700 rounded-lg text-white"
              />
            </div>
          </div>

          {/* Team Names */}
          <div className="space-y-2">
            <label className="block text-lg font-medium text-surface-200">
              Team Names
            </label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {formData.teamNames.map((name, index) => (
                <input
                  key={index}
                  type="text"
                  value={name}
                  onChange={e => setFormData(prev => ({
                    ...prev,
                    teamNames: prev.teamNames.map((n, i) => i === index ? e.target.value : n)
                  }))}
                  className="w-full px-4 py-2 border rounded-lg"
                />
              ))}
            </div>
          </div>
        </div>

        {/* Prizes */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-surface-200">Prizes</h3>
          <div className="space-y-4">
            <input
              type="text"
              placeholder="1st Place Prize"
              value={formData.prizes.first}
              onChange={(e) => handlePrizeChange('first', e.target.value)}
              className="w-full px-4 py-2 bg-surface-800 border border-surface-700 rounded-lg text-white"
            />
            <input
              type="text"
              placeholder="2nd Place Prize (Optional)"
              value={formData.prizes.second}
              onChange={(e) => handlePrizeChange('second', e.target.value)}
              className="w-full px-4 py-2 bg-surface-800 border border-surface-700 rounded-lg text-white"
            />
            <input
              type="text"
              placeholder="3rd Place Prize (Optional)"
              value={formData.prizes.third}
              onChange={(e) => handlePrizeChange('third', e.target.value)}
              className="w-full px-4 py-2 bg-surface-800 border border-surface-700 rounded-lg text-white"
            />
          </div>
        </div>

        {/* Rules */}
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium text-surface-200">
              Tournament Rules
            </h3>
            <button
              type="button"
              onClick={addRule}
              className="text-blue-500 hover:text-blue-600"
            >
              <Plus size={20} />
            </button>
          </div>
          
          <div className="space-y-2">
            {formData.rules.map((rule, index) => (
              <div key={index} className="flex gap-2">
                <input
                  type="text"
                  value={rule}
                  onChange={e => updateRule(index, e.target.value)}
                  className="flex-1 px-4 py-2 border rounded-lg"
                />
                <button
                  type="button"
                  onClick={() => removeRule(index)}
                  className="text-red-500 hover:text-red-600"
                >
                  <Trash2 size={20} />
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Settings */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-surface-200">
            Tournament Settings
          </h3>
          
          <div className="space-y-4">
            <label className="flex items-center gap-2 text-surface-200">
              <input
                type="checkbox"
                checked={formData.companyOnly}
                onChange={(e) => handleChange('companyOnly', e.target.checked)}
                className="w-5 h-5 rounded border-surface-600"
              />
              Company Employees Only
            </label>
            <label className="flex items-center gap-2 text-surface-200">
              <input
                type="checkbox"
                checked={formData.notificationsEnabled}
                onChange={(e) => handleChange('notificationsEnabled', e.target.checked)}
                className="w-5 h-5 rounded border-surface-600"
              />
              Enable Tournament Notifications
            </label>
          </div>
        </div>
      </div>

      {/* Form Actions */}
      <div className="sticky bottom-0 bg-white dark:bg-gray-900 py-4 border-t dark:border-gray-700 mt-6">
        <div className="flex justify-end gap-3">
          <button
            type="button"
            onClick={() => {
              if (onCancel) onCancel();
            }}
            className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 dark:text-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isEditing ? 'Update Tournament' : 'Create Tournament'}
          </button>
        </div>
      </div>
    </form>
  );
}

export default TournamentForm; 