import React, { useState, useEffect } from 'react';
import { Timestamp, doc, getDoc } from 'firebase/firestore';
import { X, Trophy, Target, Award, Star } from 'lucide-react';
import { createPortal } from 'react-dom';
import { db, auth } from '../firebase';
import { toast } from 'react-hot-toast';

interface StatsDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

interface UserStats {
  totalGames: number;
  gamesWon: number;
  gamesLost: number;
  bestStreak: number;
  currentStreak: number;
  averageGuesses: number;
  lastPlayed?: Timestamp;
}

// Helper function to safely format stats
const formatStat = (value: number | undefined): number => {
  return typeof value === 'number' ? value : 0;
};

export function StatsDialog({ isOpen, onClose }: StatsDialogProps) {
  const [stats, setStats] = useState<UserStats | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      if (!auth.currentUser) {
        setLoading(false);
        return;
      }
      
      try {
        const scoreDoc = await getDoc(doc(db, 'scores', auth.currentUser.uid));
        if (scoreDoc.exists()) {
          const data = scoreDoc.data();
          setStats({
            totalGames: formatStat(data.totalGames),
            gamesWon: formatStat(data.gamesWon),
            gamesLost: formatStat(data.gamesLost),
            bestStreak: formatStat(data.bestStreak),
            currentStreak: formatStat(data.currentStreak),
            averageGuesses: formatStat(data.averageGuesses),
            lastPlayed: data.lastPlayed
          });
        }
      } catch (error) {
        console.error('Error fetching stats:', error);
        toast.error('Failed to load stats');
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      setLoading(true);
      fetchStats();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return createPortal(
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-gray-800 rounded-lg p-6 max-w-lg w-full relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          <X size={24} />
        </button>
        <h2 className="text-2xl font-bold text-white mb-6">Your Stats</h2>
        
        {loading ? (
          <div className="text-center text-gray-400">Loading stats...</div>
        ) : !stats ? (
          <div className="text-center text-gray-400">No stats available yet. Play some games!</div>
        ) : (
          <div className="grid grid-cols-2 gap-4">
            <StatCard
              icon={<Trophy className="text-yellow-400" />}
              label="Games Won"
              value={formatStat(stats.gamesWon)}
            />
            <StatCard
              icon={<Target className="text-blue-400" />}
              label="Total Games"
              value={formatStat(stats.totalGames)}
            />
            <StatCard
              icon={<Award className="text-emerald-400" />}
              label="Win Rate"
              value={`${stats.totalGames ? ((stats.gamesWon / stats.totalGames) * 100).toFixed(1) : 0}%`}
            />
            <StatCard
              icon={<Star className="text-purple-400" />}
              label="Best Streak"
              value={formatStat(stats.bestStreak)}
            />
          </div>
        )}
      </div>
    </div>,
    document.body
  );
}

interface StatCardProps {
  icon: React.ReactNode;
  label: string;
  value: number | string;
}

function StatCard({ icon, label, value }: StatCardProps) {
  return (
    <div className="bg-gray-700/50 rounded-lg p-4 flex flex-col items-center justify-center">
      <div className="mb-2">{icon}</div>
      <div className="text-2xl font-bold text-white mb-1">{value}</div>
      <div className="text-sm text-gray-400">{label}</div>
    </div>
  );
}

export default StatsDialog;