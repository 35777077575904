import React, { useState, useEffect } from 'react'
import { 
  Users, Search, Filter, MoreVertical, Shield, Ban, 
  UserCheck, UserX, Mail, Loader2, AlertTriangle, User, UserMinus, UserPlus 
} from 'lucide-react'
import { 
  collection, query, getDocs, doc, updateDoc, 
  where, orderBy, limit, startAfter, 
  serverTimestamp, DocumentData, setDoc, deleteDoc 
} from 'firebase/firestore'
import { db, auth } from '../../firebase'
import { UserData } from '../../types'
import toast from 'react-hot-toast'

interface UserManagementProps {
  hideHeader?: boolean;
}

export function UserManagement({ hideHeader = false }: UserManagementProps) {
  const [users, setUsers] = useState<UserData[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [filterRole, setFilterRole] = useState<'all' | 'admin' | 'user'>('all')
  const [filterStatus, setFilterStatus] = useState<'all' | 'active' | 'blocked'>('all')

  useEffect(() => {
    loadUsers()
  }, [filterRole, filterStatus])

  const loadUsers = async () => {
    setIsLoading(true)
    try {
      let usersQuery = query(
        collection(db, 'users'),
        orderBy('createdAt', 'desc')
      )

      const snapshot = await getDocs(usersQuery)
      const userData = snapshot.docs.map(doc => ({
        ...doc.data(),
        uid: doc.id
      })) as UserData[]

      setUsers(userData)
    } catch (error) {
      console.error('Error loading users:', error)
      toast.error('Failed to load users')
    } finally {
      setIsLoading(false)
    }
  }

  const toggleUserRole = async (userId: string, makeAdmin: boolean) => {
    try {
      const userRef = doc(db, 'users', userId)
      const adminRef = doc(db, 'admins', userId)

      if (makeAdmin) {
        // Make user an admin
        await setDoc(adminRef, {
          addedBy: auth.currentUser?.email,
          addedAt: serverTimestamp(),
          isAdmin: true
        })
        await updateDoc(userRef, { isAdmin: true })
      } else {
        // Remove admin status
        await deleteDoc(adminRef)
        await updateDoc(userRef, { isAdmin: false })
      }

      // Refresh user list
      await loadUsers()
      toast.success(`User ${makeAdmin ? 'promoted to' : 'removed from'} admin`)
    } catch (error) {
      console.error('Error toggling user role:', error)
      toast.error('Failed to update user role')
    }
  }

  return (
    <div className="space-y-6">
      {!hideHeader && (
        <div className="text-surface-200">User Management</div>
      )}
      
      {/* User List */}
      <div className="space-y-4">
        {users.map((user) => (
          <div
            key={user.uid}
            className="bg-surface-800 rounded-lg p-4 space-y-2 hover:bg-surface-700 transition-colors"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                {user.photoURL ? (
                  <img 
                    src={user.photoURL} 
                    alt={user.displayName || 'User'} 
                    className="w-10 h-10 rounded-full"
                  />
                ) : (
                  <div className="w-10 h-10 rounded-full bg-surface-700 flex items-center justify-center">
                    <User className="w-6 h-6 text-surface-400" />
                  </div>
                )}
                <div>
                  <h4 className="font-medium text-white">{user.displayName}</h4>
                  <p className="text-sm text-surface-300">{user.email}</p>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <span className={`px-2 py-1 rounded-full text-xs font-medium
                  ${user.isAdmin ? 
                    'bg-emerald-500/10 text-emerald-400 border border-emerald-500/20' : 
                    'bg-surface-700/50 text-surface-300 border border-surface-600/20'}`}
                >
                  {user.isAdmin ? 'Admin' : 'User'}
                </span>
                <button
                  onClick={() => toggleUserRole(user.uid, !user.isAdmin)}
                  className="text-surface-300 hover:text-white transition-colors"
                >
                  {user.isAdmin ? <UserMinus size={20} /> : <UserPlus size={20} />}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default UserManagement 