import React from 'react';
import { Tournament } from '../types';
import { X, Trophy, Users, Calendar } from 'lucide-react';

interface TournamentAlertProps {
  tournament?: Tournament;
  onClose: () => void;
}

export function TournamentAlert({ tournament, onClose }: TournamentAlertProps) {
  if (!tournament) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-lg w-full mx-4 p-6">
        {/* Header */}
        <div className="flex justify-between items-start mb-4">
          <h2 className="text-xl font-bold text-gray-900 dark:text-white">
            Tournament Alert
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
          >
            <X size={24} />
          </button>
        </div>

        {/* Tournament Info */}
        <div className="space-y-4">
          <div>
            <h3 className="text-lg font-semibold flex items-center gap-2">
              <Trophy className="text-yellow-500" size={20} />
              {tournament.name}
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mt-1">
              {tournament.description}
            </p>
          </div>

          {/* Tournament Details */}
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
                <Users size={16} />
                <span>Teams: {tournament.teams.length}/{tournament.maxTeams}</span>
              </div>
              <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
                <Calendar size={16} />
                <span>Team Size: {tournament.teamSize}</span>
              </div>
            </div>
          </div>

          {/* Prizes Section */}
          {tournament.prizes && (
            <div className="space-y-2">
              <h4 className="font-semibold">Prizes</h4>
              <div className="space-y-1">
                <p className="text-yellow-500">🥇 1st Place: {tournament.prizes.first}</p>
                {tournament.prizes.second && (
                  <p className="text-gray-400">🥈 2nd Place: {tournament.prizes.second}</p>
                )}
                {tournament.prizes.third && (
                  <p className="text-amber-600">🥉 3rd Place: {tournament.prizes.third}</p>
                )}
              </div>
            </div>
          )}

          {/* Rules Section */}
          {tournament.rules && tournament.rules.length > 0 && (
            <div className="mt-4">
              <h4 className="font-semibold">Rules:</h4>
              <ul className="list-disc pl-5">
                {tournament.rules.map((rule: string, index: number) => (
                  <li key={index}>{rule}</li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* Action Buttons */}
        <div className="mt-6 flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 dark:text-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
} 