import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { 
  X, LogOut, UserCircle, Shield, 
  Trophy, HelpCircle, BookOpen,
  History, Settings, Sparkles, Scale
} from 'lucide-react'
import { auth, db, getUserData, isUserAdmin } from '../firebase'
import { signOut } from 'firebase/auth'
import { doc, onSnapshot } from 'firebase/firestore'
import AuthDialog from './AuthDialog'
import { Rules } from './Rules'
import AdminConsole from './AdminConsole'
import Leaderboard from './Leaderboard'
import toast from 'react-hot-toast'
import StatsDialog from './StatsDialog'
import SettingsDialog from './SettingsDialog'
import { useMediaQuery } from '../hooks/useMediaQuery'
import TermsOfService from './TermsOfService'
import PrivacyPolicy from './PrivacyPolicy'
import { useStore } from '../store'

interface FlyoutMenuProps {
  isOpen: boolean
  onClose: () => void
}

export default function FlyoutMenu({ isOpen, onClose }: FlyoutMenuProps) {
  const [showAuth, setShowAuth] = useState(false)
  const [showRules, setShowRules] = useState(false)
  const [showAdminConsole, setShowAdminConsole] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [showTOS, setShowTOS] = useState(false)
  const [showPrivacy, setShowPrivacy] = useState(false)
  const user = auth.currentUser

  const isMobile = useMediaQuery('(max-width: 768px)')

  const setShowLeaderboard = useStore(state => state.setShowLeaderboard)
  const setShowStats = useStore(state => state.setShowStats)

  // Check if user is admin using the helper function
  useEffect(() => {
    const checkAdmin = async () => {
      if (!user) {
        setIsAdmin(false)
        return
      }

      try {
        const adminStatus = await isUserAdmin(user)
        setIsAdmin(adminStatus)
      } catch (error) {
        console.error('Error checking admin status:', error)
        setIsAdmin(false)
      }
    }

    checkAdmin()
  }, [user])

  const handleModalOpen = (setModalState: (state: boolean) => void, isSettings: boolean = false) => {
    if (!isSettings && user) return; // Don't open auth dialog if user is logged in
    if (auth.currentUser && !isSettings) return; // Additional check for logged in user
    onClose(); // Close the menu first
    setTimeout(() => {
      setModalState(true);
    }, 300);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth)
      toast.success('Signed out successfully')
      onClose()
    } catch (error) {
      console.error('Error signing out:', error)
      toast.error('Failed to sign out')
    }
  }

  const handleShowLeaderboard = () => {
    setShowLeaderboard(true);
    onClose();
  };

  const handleShowStats = () => {
    setShowStats(true);
    onClose();
  };

  return (
    <>
      {isOpen && (
        <>
          {createPortal(
            <div className={`fixed inset-0 bg-black/90 backdrop-blur-sm flex z-[100] ${isMobile ? 'flex-col' : 'justify-end'}`}>
              <div className={`bg-gray-900 ${isMobile ? 'w-full h-full' : 'w-full max-w-sm h-full'} overflow-y-auto`}>
                {/* Header */}
                <div className="p-4 border-b border-gray-800 flex items-center justify-between">
                  <h2 className="text-xl font-bold text-white">Menu</h2>
                  <button onClick={onClose} className="text-gray-400 hover:text-white">
                    <X size={24} />
                  </button>
                </div>

                {/* Navigation */}
                <nav className="p-4 space-y-6">
                  {/* Game Section */}
                  <div className="space-y-2">
                    <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wider px-4">
                      Game
                    </h3>
                    <div className="space-y-1">
                      <button
                        onClick={() => handleModalOpen(setShowRules)}
                        className="w-full flex items-center gap-3 px-4 py-3 text-left text-gray-300 hover:text-white hover:bg-gray-800 rounded-lg transition-colors"
                      >
                        <HelpCircle size={20} />
                        How to Play
                      </button>
                      
                      {/* Only show these for logged-in users */}
                      {user && (
                        <button
                          onClick={handleShowLeaderboard}
                          className="w-full flex items-center gap-3 px-4 py-3 text-left text-gray-300 hover:text-white hover:bg-gray-800 rounded-lg transition-colors"
                        >
                          <Trophy size={20} />
                          Leaderboard
                        </button>
                      )}
                    </div>
                  </div>

                  {/* Account Section */}
                  <div className="space-y-2">
                    <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wider px-4">
                      Account
                    </h3>
                    <div className="space-y-1">
                      {user ? (
                        <>
                          <button
                            onClick={handleShowStats}
                            className="w-full flex items-center gap-3 px-4 py-3 text-left text-gray-300 hover:text-white hover:bg-gray-800 rounded-lg transition-colors"
                          >
                            <History size={20} />
                            Your Stats
                          </button>
                          <button
                            onClick={() => handleModalOpen(setShowSettings, true)}
                            className="w-full flex items-center gap-3 px-4 py-3 text-left text-gray-300 hover:text-white hover:bg-gray-800 rounded-lg transition-colors"
                          >
                            <Settings size={20} />
                            Settings
                          </button>
                          <button
                            onClick={handleSignOut}
                            className="w-full flex items-center gap-3 px-4 py-3 text-left text-gray-300 hover:text-white hover:bg-gray-800 rounded-lg transition-colors"
                          >
                            <LogOut size={20} />
                            Sign Out
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={() => handleModalOpen(setShowAuth)}
                          className="w-full flex items-center gap-3 px-4 py-3 text-left text-emerald-400 hover:text-emerald-300 hover:bg-gray-800 rounded-lg transition-colors"
                        >
                          <UserCircle size={20} />
                          Sign In to Play 
                        </button>
                      )}
                    </div>
                  </div>

                  {/* Mobile-only Sponsors Section */}
                  {isMobile && !user && (
                    <div className="space-y-2">
                      <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wider px-4">
                        Sponsors
                      </h3>
                      <div className="px-4">
                        <a 
                          href="https://engineeredtaxservices.com" 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="group relative overflow-hidden rounded-xl bg-gradient-to-br from-gray-800 to-gray-900 p-1 w-full block"
                        >
                          <div className="absolute inset-0 bg-gradient-to-r from-emerald-500 to-emerald-700 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                          
                          <div className="relative bg-gray-900 rounded-lg p-4 transition-transform duration-300 group-hover:transform group-hover:scale-[0.98]">
                            <div className="aspect-video relative overflow-hidden rounded-md bg-gray-800 flex items-center justify-center">
                              <img 
                                src="/images/ETS_horizontal.png"
                                alt="Engineered Tax Services"
                                className="w-full h-full object-contain p-4 transform transition-all duration-300 group-hover:scale-105"
                                onError={(e) => {
                                  console.error('Error loading image:', e);
                                  e.currentTarget.style.display = 'none';
                                }}
                              />
                              <div className="absolute inset-0 bg-gradient-to-t from-emerald-500/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                              <div className="absolute bottom-0 left-0 right-0 p-4 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300">
                                <span className="text-xs text-white/90 bg-emerald-500/90 px-2 py-1 rounded-full">
                                  Visit Website
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  )}

                  {/* Mobile-only Footer Links */}
                  {isMobile && (
                    <div className="space-y-2 pt-4 border-t border-gray-800">
                      <div className="space-y-1">
                        <a 
                          href="mailto:taxleapp@gmail.com"
                          className="w-full flex items-center gap-3 px-4 py-3 text-left text-gray-300 hover:text-white hover:bg-gray-800 rounded-lg transition-colors"
                        >
                          <Sparkles size={20} className="text-emerald-400" />
                          Become a Sponsor
                        </a>
                        <button
                          onClick={() => setShowTOS(true)}
                          className="w-full flex items-center gap-3 px-4 py-3 text-left text-gray-300 hover:text-white hover:bg-gray-800 rounded-lg transition-colors"
                        >
                          <Scale size={20} className="text-emerald-400" />
                          Terms of Service
                        </button>
                        <button
                          onClick={() => setShowPrivacy(true)}
                          className="w-full flex items-center gap-3 px-4 py-3 text-left text-gray-300 hover:text-white hover:bg-gray-800 rounded-lg transition-colors"
                        >
                          <Shield size={20} className="text-emerald-400" />
                          Privacy Policy
                        </button>
                        <div className="px-4 py-3 text-sm text-gray-500">
                          © {new Date().getFullYear()} Taxle. All rights reserved.
                        </div>
                      </div>
                    </div>
                  )}
                </nav>
              </div>
            </div>,
            document.body
          )}
        </>
      )}

      <AuthDialog isOpen={showAuth} onClose={() => setShowAuth(false)} />
      <Rules isOpen={showRules} onClose={() => setShowRules(false)} />
      {isAdmin && (
        <AdminConsole isOpen={showAdminConsole} onClose={() => setShowAdminConsole(false)} />
      )}
      <SettingsDialog isOpen={showSettings} onClose={() => setShowSettings(false)} />
      <TermsOfService isOpen={showTOS} onClose={() => setShowTOS(false)} />
      <PrivacyPolicy isOpen={showPrivacy} onClose={() => setShowPrivacy(false)} />
    </>
  )
}
