import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { Users, Crown, UserPlus, Trash2 } from 'lucide-react';
import { Tournament, TournamentTeam, TeamMember } from '../../types';
import toast from 'react-hot-toast';

interface TeamManagementProps {
  tournamentId: string;
  tournament?: Tournament;
  onUpdate?: () => void;
}

export function TeamManagement({ tournamentId, tournament, onUpdate = () => {} }: TeamManagementProps) {
  const [users, setUsers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState<TournamentTeam | null>(null);
  const [localTournament, setLocalTournament] = useState<Tournament | null>(tournament || null);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      const usersRef = collection(db, 'users');
      const snapshot = await getDocs(usersRef);
      const userData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUsers(userData);
    } catch (error) {
      console.error('Error loading users:', error);
      toast.error('Failed to load users');
    } finally {
      setIsLoading(false);
    }
  };

  const createTeam = async (name: string) => {
    if (!localTournament) return;
    const newTeam: Omit<TournamentTeam, 'id'> = {
      name,
      members: [],
      score: 0,
      gamesPlayed: 0,
      createdAt: Timestamp.now()
    };
    // ... rest of the function
  };

  const handleAddTeam = () => {
    if (!localTournament) return;
    createTeam(`Team ${(localTournament.teams?.length || 0) + 1}`);
  };

  const addMemberToTeam = async (teamId: string, user: any) => {
    if (!localTournament) return;

    const team = localTournament.teams.find(t => t.id === teamId);
    if (!team) return;

    if (team.members.length >= (localTournament?.teamSize || 0)) {
      toast.error('Team is full');
      return;
    }

    if (localTournament.participants.includes(user.id)) {
      toast.error('User is already in a team');
      return;
    }

    const newMember: TeamMember = {
      userId: user.id,
      displayName: user.displayName,
      photoURL: user.photoURL,
      role: team.members.length === 0 ? 'captain' : 'member',
      joinedAt: Timestamp.now()
    };

    try {
      const updatedTeams = localTournament.teams.map(t => {
        if (t.id === teamId) {
          return {
            ...t,
            members: [...t.members, newMember],
            updatedAt: Timestamp.now()
          };
        }
        return t;
      });

      await updateDoc(doc(db, 'tournaments', localTournament.id), {
        teams: updatedTeams,
        participants: [...localTournament.participants, user.id],
        updatedAt: Timestamp.now()
      });
      onUpdate();
      toast.success('Member added to team');
    } catch (error) {
      console.error('Error adding team member:', error);
      toast.error('Failed to add team member');
    }
  };

  const teamCount = localTournament?.teams?.length || 0;

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
          Teams
        </h3>
        <button
          onClick={handleAddTeam}
          className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          disabled={teamCount >= (localTournament?.maxTeams || 0)}
        >
          <Users size={20} />
          Add Team
        </button>
      </div>

      <div className="grid gap-4">
        {localTournament?.teams.map(team => (
          <div
            key={team.id}
            className="bg-white dark:bg-gray-800 rounded-lg shadow p-4 space-y-4"
          >
            <div className="flex justify-between items-center">
              <h4 className="text-lg font-medium">{team.name}</h4>
              <div className="text-sm text-gray-500">
                {team.members.length} / {(localTournament?.teamSize || 0)} members
              </div>
            </div>

            <div className="space-y-2">
              {team.members.map(member => (
                <div
                  key={member.userId}
                  className="flex items-center justify-between bg-gray-50 dark:bg-gray-700 p-2 rounded"
                >
                  <div className="flex items-center gap-2">
                    {member.photoURL && (
                      <img
                        src={member.photoURL}
                        alt={member.displayName}
                        className="w-8 h-8 rounded-full"
                      />
                    )}
                    <span>{member.displayName}</span>
                    {member.role === 'captain' && (
                      <Crown size={16} className="text-yellow-500" />
                    )}
                  </div>
                </div>
              ))}
            </div>

            {team.members.length < (localTournament?.teamSize || 0) && (
              <div className="mt-2">
                <select
                  onChange={(e) => {
                    const user = users.find(u => u.id === e.target.value);
                    if (user) addMemberToTeam(team.id, user);
                  }}
                  className="w-full p-2 border rounded dark:bg-gray-700"
                  defaultValue=""
                >
                  <option value="" disabled>Add team member...</option>
                  {users
                    .filter(user => !localTournament?.participants.includes(user.id))
                    .map(user => (
                      <option key={user.id} value={user.id}>
                        {user.displayName}
                      </option>
                    ))}
                </select>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TeamManagement; 