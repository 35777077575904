import React, { useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { 
  X, Camera, User, Building2, Mail, Phone, 
  Globe2, Bell, Moon, Volume2, VolumeX,
  Loader2, CheckCircle2, AlertCircle, Settings, Plus, Pencil, Trash2, Globe, Users, Save,
  Trophy, GamepadIcon, ShieldCheck, Crown
} from 'lucide-react'
import { auth, db, storage } from '../firebase'
import { doc, updateDoc, getDoc, setDoc, getDocs, collection, serverTimestamp, deleteDoc, writeBatch } from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { updateProfile } from 'firebase/auth'
import toast from 'react-hot-toast'
import { UserProfile, UserData, GameVariant, Team } from '../types'
import { getUserData } from '../firebase'
import { gameSounds } from '../utils/sounds'
import { UserManagement } from './admin/UserManagement'
import { TournamentManagement } from './admin/TournamentManagement'

interface SettingsDialogProps {
  isOpen: boolean
  onClose: () => void
}

type Tab = 'profile' | 'preferences' | 'tournaments' | 'game-variants' | 'user-management'

export function SettingsDialog({ isOpen, onClose }: SettingsDialogProps) {
  const [activeTab, setActiveTab] = useState<Tab>('profile')
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [profile, setProfile] = useState<UserProfile>({
    displayName: '',
    photoURL: '',
    department: '',
    email: '',
    phone: '',
    location: '',
    company: ''
  })
  const [preferences, setPreferences] = useState({
    notifications: true,
    darkMode: false,
    soundEffects: true
  })
  const fileInputRef = useRef<HTMLInputElement>(null)
  const user = auth.currentUser
  const [error, setError] = useState<string | null>(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [users, setUsers] = useState<UserData[]>([])
  
  // Effect to handle dark mode
  useEffect(() => {
    if (preferences.darkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [preferences.darkMode])
  
  const loadUserData = async () => {
    if (!user) return
    
    setIsLoading(true)
    setError(null)
    try {
      console.log('Loading user data for:', user.email)
      const userData = await getUserData(user.uid)
      
      if (userData) {
        console.log('User data loaded:', userData)
        const profileData: UserProfile = {
          displayName: userData.displayName || '',
          photoURL: userData.photoURL || '',
          department: userData.department || '',
          email: userData.email || '',
          phone: userData.phone || '',
          location: userData.location || '',
          company: userData.company || ''
        }
        setProfile(profileData)
        
        if (userData.isAdmin) {
          console.log('Admin user detected from userData')
          setIsAdmin(true)
          await loadAdminData()
        }
        
        const soundEnabled = userData.soundEffects ?? true
        const darkMode = userData.darkMode ?? false
        setPreferences({
          notifications: userData.notifications ?? true,
          darkMode: darkMode,
          soundEffects: soundEnabled
        })
        gameSounds.setEnabled(soundEnabled)
      }
    } catch (error) {
      console.error('Error loading user data:', error)
      setError('Failed to load user data. Please try again.')
      toast.error('Failed to load user data')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      loadUserData()
    }
  }, [isOpen])

  const handleSave = async () => {
    if (!user) return
    
    setIsSaving(true)
    try {
      const userRef = doc(db, 'users', user.uid)
      await updateDoc(userRef, {
        ...profile,
        ...preferences,
        updatedAt: serverTimestamp()
      })
      
      if (profile.displayName) {
        await updateProfile(user, {
          displayName: profile.displayName,
          photoURL: profile.photoURL || null
        })
      }
      
      toast.success('Settings saved successfully')
      onClose()
    } catch (error) {
      console.error('Error saving settings:', error)
      toast.error('Failed to save settings')
    } finally {
      setIsSaving(false)
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file || !user) return
    
    try {
      const storageRef = ref(storage, `profile-photos/${user.uid}`)
      await uploadBytes(storageRef, file)
      const photoURL = await getDownloadURL(storageRef)
      setProfile(prev => ({ ...prev, photoURL }))
    } catch (error) {
      console.error('Error uploading photo:', error)
      toast.error('Failed to upload photo')
    }
  }

  const loadAdminData = async () => {
    // Admin data loading logic here
  }

  const loadUsers = async () => {
    if (!isAdmin) return;
    
    try {
      const usersRef = collection(db, 'users');
      const snapshot = await getDocs(usersRef);
      const usersData = snapshot.docs.map(doc => ({
        ...doc.data(),
        uid: doc.id
      })) as UserData[];
      setUsers(usersData);
    } catch (error) {
      console.error('Error loading users:', error);
      toast.error('Failed to load users');
    }
  };

  useEffect(() => {
    if (activeTab === 'user-management' && isAdmin) {
      loadUsers();
    }
  }, [activeTab, isAdmin]);

  // Update the input and text styling for better visibility in both modes
  const inputClasses = "w-full px-4 py-2.5 bg-gray-800 border border-gray-700 rounded-lg text-white focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20 transition-all" as const;

  // Update the tab styles and header area
  const tabClasses = (isActive: boolean) => `
    px-4 py-2 rounded-lg text-sm font-medium transition-all
    ${isActive 
      ? 'bg-blue-500 text-white shadow-lg shadow-blue-500/25' 
      : 'text-gray-400 hover:text-white hover:bg-gray-800'
    }
  `;

  if (!isOpen) return null

  return createPortal(
    <div className="fixed inset-0 z-[1001] flex items-center justify-center p-4">
      <div className="fixed inset-0 bg-black/90 backdrop-blur-sm" onClick={onClose} />
      <div className="relative bg-gray-900 w-full max-w-4xl rounded-2xl overflow-hidden shadow-2xl border border-gray-800">
        {/* Header */}
        <div className="p-6 border-b border-gray-800 flex items-center justify-between">
          <h2 className="text-2xl font-bold text-white">Settings</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white p-2 rounded-full hover:bg-gray-800/50 transition-colors"
          >
            <X size={24} />
          </button>
        </div>

        {/* Content */}
        <div className="flex flex-col md:flex-row h-[calc(100vh-200px)] max-h-[600px]">
          {/* Sidebar - now top bar on mobile */}
          <div className="w-full md:w-64 border-b md:border-b-0 md:border-r border-gray-800 p-4 space-y-2">
            <div className="flex md:flex-col overflow-x-auto md:overflow-x-visible space-x-2 md:space-x-0 md:space-y-1">
              <button
                onClick={() => setActiveTab('profile')}
                className={`flex-shrink-0 flex items-center gap-2 px-4 py-2.5 rounded-lg transition-all ${
                  activeTab === 'profile' 
                    ? 'bg-accent-500 text-white shadow-glow' 
                    : 'text-surface-200 hover:bg-surface-800 hover:text-white'
                }`}
              >
                <User size={20} />
                Profile
              </button>

              <button
                onClick={() => setActiveTab('preferences')}
                className={`flex-shrink-0 flex items-center gap-2 px-4 py-2.5 rounded-lg transition-all ${
                  activeTab === 'preferences' 
                    ? 'bg-accent-500 text-white shadow-glow' 
                    : 'text-surface-200 hover:bg-surface-800 hover:text-white'
                }`}
              >
                <Settings size={20} />
                Preferences
              </button>

              {isAdmin && (
                <>
                  <button
                    onClick={() => setActiveTab('tournaments')}
                    className={`flex-shrink-0 flex items-center gap-2 px-4 py-2.5 rounded-lg transition-all ${
                      activeTab === 'tournaments' 
                        ? 'bg-accent-500 text-white shadow-glow' 
                        : 'text-surface-200 hover:bg-surface-800 hover:text-white'
                    }`}
                  >
                    <Trophy size={20} />
                    Tournaments
                  </button>

                  <button
                    onClick={() => setActiveTab('user-management')}
                    className={`flex-shrink-0 flex items-center gap-2 px-4 py-2.5 rounded-lg transition-all ${
                      activeTab === 'user-management' 
                        ? 'bg-accent-500 text-white shadow-glow' 
                        : 'text-surface-200 hover:bg-surface-800 hover:text-white'
                    }`}
                  >
                    <Users size={20} />
                    User Management
                  </button>
                </>
              )}
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 p-6 overflow-y-auto custom-scrollbar">
            {/* Profile Tab */}
            {activeTab === 'profile' && (
              <div className="space-y-6">
                <h2 className="text-2xl font-semibold text-white">Profile</h2>
                <div className="space-y-6">
                  {/* Profile Photo Section */}
                  <div className="flex items-center gap-6">
                    <div className="relative group">
                      {profile.photoURL ? (
                        <img
                          src={profile.photoURL}
                          alt="Profile"
                          className="w-24 h-24 rounded-full object-cover border-4 border-gray-800"
                        />
                      ) : (
                        <div className="w-24 h-24 rounded-full bg-gray-800 flex items-center justify-center">
                          <User size={32} className="text-gray-600" />
                        </div>
                      )}
                      <button
                        onClick={() => fileInputRef.current?.click()}
                        className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <Camera size={20} className="text-white" />
                      </button>
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-white mb-1">Profile Photo</h3>
                      <p className="text-gray-400 text-sm">
                        Click to upload a new profile photo
                      </p>
                    </div>
                  </div>

                  {/* Form Fields */}
                  <div className="grid grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-300">
                        Display Name
                      </label>
                      <input
                        type="text"
                        value={profile.displayName ?? ''}
                        onChange={(e) => setProfile(prev => ({ ...prev, displayName: e.target.value }))}
                        className={inputClasses}
                      />
                    </div>
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-300">
                        Department
                      </label>
                      <input
                        type="text"
                        value={profile.department ?? ''}
                        onChange={(e) => setProfile(prev => ({ ...prev, department: e.target.value }))}
                        className={inputClasses}
                      />
                    </div>
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-300">
                        Email
                      </label>
                      <input
                        type="email"
                        value={profile.email ?? ''}
                        onChange={(e) => setProfile(prev => ({ ...prev, email: e.target.value }))}
                        className={inputClasses}
                      />
                    </div>
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-300">
                        Phone
                      </label>
                      <input
                        type="tel"
                        value={profile.phone ?? ''}
                        onChange={(e) => setProfile(prev => ({ ...prev, phone: e.target.value }))}
                        className={inputClasses}
                      />
                    </div>
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-300">
                        Location
                      </label>
                      <input
                        type="text"
                        value={profile.location ?? ''}
                        onChange={(e) => setProfile(prev => ({ ...prev, location: e.target.value }))}
                        className={inputClasses}
                      />
                    </div>
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-300">
                        Company
                      </label>
                      <input
                        type="text"
                        value={profile.company ?? ''}
                        onChange={(e) => setProfile(prev => ({ ...prev, company: e.target.value }))}
                        className={inputClasses}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Preferences Tab */}
            {activeTab === 'preferences' && (
              <div className="space-y-6">
                <h2 className="text-2xl font-semibold text-white">Preferences</h2>
                <div className="space-y-4">
                  <div className="flex items-center justify-between p-4 bg-gray-800 rounded-lg">
                    <div className="flex items-center space-x-2">
                      <Bell size={18} className="text-gray-300" />
                      <span className="text-white">Notifications</span>
                    </div>
                    <button
                      onClick={() => setPreferences(prev => ({ ...prev, notifications: !prev.notifications }))}
                      className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                        preferences.notifications ? 'bg-blue-500' : 'bg-gray-200 dark:bg-gray-700'
                      }`}
                    >
                      <span
                        className={`inline-block h-4 w-4 transform rounded-full bg-white transition ${
                          preferences.notifications ? 'translate-x-6' : 'translate-x-1'
                        }`}
                      />
                    </button>
                  </div>

                  <div className="flex items-center justify-between p-4 bg-gray-800 rounded-lg">
                    <div className="flex items-center space-x-2">
                      <Moon size={18} className="text-gray-300" />
                      <span className="text-white">Dark Mode</span>
                    </div>
                    <button
                      onClick={() => setPreferences(prev => ({ ...prev, darkMode: !prev.darkMode }))}
                      className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                        preferences.darkMode ? 'bg-blue-500' : 'bg-gray-200 dark:bg-gray-700'
                      }`}
                    >
                      <span
                        className={`inline-block h-4 w-4 transform rounded-full bg-white transition ${
                          preferences.darkMode ? 'translate-x-6' : 'translate-x-1'
                        }`}
                      />
                    </button>
                  </div>

                  <div className="flex items-center justify-between p-4 bg-gray-800 rounded-lg">
                    <div className="flex items-center space-x-2">
                      {preferences.soundEffects ? (
                        <Volume2 size={18} className="text-gray-300" />
                      ) : (
                        <VolumeX size={18} className="text-gray-300" />
                      )}
                      <span className="text-white">Sound Effects</span>
                    </div>
                    <button
                      onClick={() => {
                        setPreferences(prev => ({ ...prev, soundEffects: !prev.soundEffects }))
                        gameSounds.setEnabled(!preferences.soundEffects)
                      }}
                      className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                        preferences.soundEffects ? 'bg-blue-500' : 'bg-gray-200 dark:bg-gray-700'
                      }`}
                    >
                      <span
                        className={`inline-block h-4 w-4 transform rounded-full bg-white transition ${
                          preferences.soundEffects ? 'translate-x-6' : 'translate-x-1'
                        }`}
                      />
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Tournaments Tab */}
            {activeTab === 'tournaments' && isAdmin && (
              <div className="space-y-6">
                <h2 className="text-2xl font-semibold text-white">Tournaments</h2>
                <TournamentManagement />
              </div>
            )}

            {/* User Management Tab */}
            {activeTab === 'user-management' && isAdmin && (
              <div className="space-y-6">
                <h2 className="text-2xl font-semibold text-white">User Management</h2>
                <div className="max-h-[calc(100vh-300px)] overflow-y-auto custom-scrollbar pr-4">
                  <UserManagement hideHeader={true} />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Footer */}
        <div className="p-6 border-t border-gray-800 flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-400 hover:text-white transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={isSaving}
            className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed transition-all"
          >
            {isSaving ? (
              <div className="flex items-center gap-2">
                <Loader2 className="animate-spin" size={18} />
                Saving...
              </div>
            ) : (
              'Save Changes'
            )}
          </button>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default SettingsDialog
