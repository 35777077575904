import { create } from 'zustand';
import type { GameState } from './types';
import { GameVariant, Tournament, TournamentTeam } from './types';
import { WORDS } from './words';
import { auth, db } from './firebase';
import { doc, updateDoc, increment, getDoc, setDoc, collection, query, where, getDocs, addDoc, serverTimestamp, limit, Timestamp, FieldValue } from 'firebase/firestore';
import toast from 'react-hot-toast';

const getRandomWord = () => WORDS[Math.floor(Math.random() * WORDS.length)];

interface ScoreData {
  userId: string
  displayName: string
  photoURL?: string | null
  totalGames: number
  gamesWon: number
  gamesLost: number
  averageGuesses: number
  lastPlayed: Timestamp | FieldValue
  currentStreak: number
  bestStreak: number
  createdAt: Timestamp | FieldValue
  updatedAt: Timestamp | FieldValue
  variantId?: string
}

async function updateTournamentScore(userId: string, isWin: boolean, guessCount: number, tournamentId?: string) {
  if (!tournamentId) return;
  
  try {
    const tournamentRef = doc(db, 'tournaments', tournamentId);
    const tournamentDoc = await getDoc(tournamentRef);
    
    if (!tournamentDoc.exists()) return;
    
    const tournament = tournamentDoc.data() as Tournament;
    const now = Timestamp.now();
    
    // Check if tournament is active
    if (tournament.startDate > now || tournament.endDate < now) {
      return;
    }
    
    // Find user's team
    const userTeam = tournament.teams.find(team => 
      team.members.some(member => member.userId === userId)
    );
    
    if (!userTeam) return;
    
    // Calculate score based on guesses (you can adjust the scoring formula)
    const score = isWin ? Math.max(7 - guessCount, 1) : 0;
    
    // Update team score
    const updatedTeams = tournament.teams.map(team => {
      if (team.id === userTeam.id) {
        return {
          ...team,
          score: (team.score || 0) + score,
          updatedAt: now
        };
      }
      return team;
    });
    
    // Sort teams by score and update ranks
    const rankedTeams = [...updatedTeams].sort((a, b) => (b.score || 0) - (a.score || 0))
      .map((team, index) => ({
        ...team,
        rank: index + 1
      }));
    
    await updateDoc(tournamentRef, {
      teams: rankedTeams,
      updatedAt: now
    });
    
  } catch (error) {
    console.error('Error updating tournament score:', error);
  }
}

async function updateUserScore({ 
  userId, 
  displayName, 
  isWin, 
  guessCount,
  variantId,
  tournamentId 
}: {
  userId: string
  displayName: string
  isWin: boolean
  guessCount: number
  variantId?: string | null
  tournamentId?: string
}) {
  const scoreRef = doc(db, 'scores', userId);
  
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('Not signed in');
    if (user.uid !== userId) throw new Error('Unauthorized to update score');

    const now = serverTimestamp();
    
    const scoreDoc = await getDoc(scoreRef);
    
    if (!scoreDoc.exists()) {
      const initialScore: Partial<ScoreData> = {
        userId,
        displayName,
        photoURL: user.photoURL,
        totalGames: 1,
        gamesWon: isWin ? 1 : 0,
        gamesLost: isWin ? 0 : 1,
        averageGuesses: guessCount,
        lastPlayed: now,
        currentStreak: isWin ? 1 : 0,
        bestStreak: isWin ? 1 : 0,
        createdAt: now,
        updatedAt: now
      };

      if (variantId) {
        initialScore.variantId = variantId;
      }

      await setDoc(scoreRef, initialScore);
      return;
    }

    const currentData = scoreDoc.data() as ScoreData;
    const totalGames = (currentData.totalGames || 0) + 1;
    
    let newStreak = 0;
    if (isWin) {
      const lastPlayed = currentData.lastPlayed instanceof Timestamp ? 
        currentData.lastPlayed.toDate() : 
        new Date();
      const isStreakActive = lastPlayed && 
        (new Date().getTime() - lastPlayed.getTime()) < 24 * 60 * 60 * 1000;
      newStreak = isStreakActive ? (currentData.currentStreak || 0) + 1 : 1;
    }

    const updateData = {
      totalGames: increment(1),
      gamesWon: isWin ? increment(1) : currentData.gamesWon,
      gamesLost: isWin ? currentData.gamesLost : increment(1),
      averageGuesses: Number((((currentData.averageGuesses || 0) * (totalGames - 1)) + guessCount) / totalGames),
      lastPlayed: now,
      currentStreak: newStreak,
      bestStreak: Math.max(newStreak, currentData.bestStreak || 0),
      updatedAt: now,
      ...(variantId && { variantId })
    } as {
      totalGames: FieldValue;
      gamesWon: number | FieldValue;
      gamesLost: number | FieldValue;
      averageGuesses: number;
      lastPlayed: FieldValue;
      currentStreak: number;
      bestStreak: number;
      updatedAt: FieldValue;
      variantId?: string;
    };

    await updateDoc(scoreRef, updateData);

    // Add tournament score update
    if (tournamentId) {
      await updateTournamentScore(userId, isWin, guessCount, tournamentId);
    }

  } catch (error: any) {
    console.error('Error updating score:', error);
    throw new Error(error.message || 'Failed to update stats');
  }
}

const getActiveGameVariant = async (): Promise<GameVariant | null> => {
  try {
    const now = Timestamp.now();
    const q = query(
      collection(db, 'gameVariants'),
      where('active', '==', true),
      where('startDate', '<=', now),
      where('endDate', '>=', now),
      limit(1)
    );
    
    const snapshot = await getDocs(q);
    if (!snapshot.empty) {
      const variantDoc = snapshot.docs[0];
      const variant = variantDoc.data() as GameVariant;
      
      if (!variant.wordList?.length) {
        console.error('Invalid variant data: missing word list');
        return null;
      }
      
      return { ...variant, id: variantDoc.id };
    }
    return null;
  } catch (error) {
    console.error('Error fetching game variant:', error);
    return null;
  }
};

export const useStore = create<GameState>((set, get) => ({
  guesses: [],
  currentGuess: '',
  gameStatus: 'playing',
  solution: getRandomWord(),
  currentVariant: null,
  guestPlays: 0,
  showSignInPrompt: false,
  weeklyWord: null,
  weeklyVariant: null,
  showLeaderboard: false,
  showStats: false,
  currentTournament: null,
  userId: auth.currentUser?.uid,
  setShowLeaderboard: (show: boolean) => set({ showLeaderboard: show }),
  setShowStats: (show: boolean) => set({ showStats: show }),
  setCurrentTournament: (tournament: Tournament | null) => set({ currentTournament: tournament }),
  isUserInTournament: (team: TournamentTeam) => {
    const userId = get().userId;
    return team.members.some(member => member.userId === userId);
  },
  
  initializeGame: async () => {
    try {
      await get().checkWeeklyGame();
      
      if (!get().weeklyWord) {
        const variant = await getActiveGameVariant();
        if (variant?.wordList?.length) {
          set({ 
            currentVariant: variant,
            solution: getRandomWord()
          });
        } else {
          set({ 
            currentVariant: null,
            solution: getRandomWord()
          });
        }
      }
    } catch (error) {
      console.error('Error initializing game:', error);
      set(state => ({
        solution: state.solution || getRandomWord()
      }));
    }
  },

  addGuess: async (guess: string) => {
    const state = get();
    if (!state.solution) return;

    const newGuesses = [...state.guesses, guess];
    let gameStatus = state.gameStatus;

    if (guess.toUpperCase() === state.solution.toUpperCase()) {
      gameStatus = 'won';
    } else if (newGuesses.length >= 6) {
      gameStatus = 'lost';
    }

    // Update stats when game is finished
    if (gameStatus !== 'playing' && auth.currentUser) {
      try {
        // First ensure user document exists
        const userRef = doc(db, 'users', auth.currentUser.uid);
        await setDoc(userRef, {
          email: auth.currentUser.email,
          displayName: auth.currentUser.displayName || auth.currentUser.email?.split('@')[0] || 'Anonymous',
          updatedAt: serverTimestamp()
        }, { merge: true });

        // Then update score
        await updateUserScore({
          userId: auth.currentUser.uid,
          displayName: auth.currentUser.displayName || auth.currentUser.email?.split('@')[0] || 'Anonymous',
          isWin: gameStatus === 'won',
          guessCount: newGuesses.length,
          variantId: state.currentVariant?.id
        });
      } catch (error) {
        console.error('Error updating score:', error);
        toast.error('Failed to update stats. Please try again.');
      }
    }
    
    set({ 
      guesses: newGuesses, 
      currentGuess: '',
      gameStatus 
    });
  },

  addLetter: (letter: string) => {
    set((state) => {
      if (state.currentGuess.length === 5) return state;
      return { currentGuess: state.currentGuess + letter };
    });
  },

  removeLetter: () => {
    set((state) => ({
      currentGuess: state.currentGuess.slice(0, -1),
    }));
  },

  resetGame: async () => {
    const state = get()
    const isGuest = !auth.currentUser

    if (isGuest) {
      const newGuestPlays = state.guestPlays + 1
      set({ guestPlays: newGuestPlays })

      if (newGuestPlays >= 2) {
        set({ showSignInPrompt: true })
        return
      }
    }

    const newSolution = getRandomWord()
    set({
      guesses: [],
      currentGuess: '',
      gameStatus: 'playing',
      solution: newSolution
    })

    try {
      await get().initializeGame()
    } catch (error) {
      console.error('Error resetting game:', error)
    }
  },

  setShowSignInPrompt: (show: boolean) => set({ showSignInPrompt: show }),

  checkWeeklyGame: async () => {
    try {
      const now = new Date()
      const startOfWeek = new Date(now)
      startOfWeek.setHours(0, 0, 0, 0)
      startOfWeek.setDate(now.getDate() - now.getDay()) // Start of current week

      const q = query(
        collection(db, 'weeklyGames'),
        where('startDate', '<=', now),
        where('endDate', '>=', now),
        limit(1)
      )

      const snapshot = await getDocs(q)
      if (!snapshot.empty) {
        const weeklyGame = snapshot.docs[0].data()
        set({ 
          weeklyWord: weeklyGame.word,
          weeklyVariant: weeklyGame.variant,
          solution: weeklyGame.word,
          currentVariant: weeklyGame.variant
        })

        // Show notification for new weekly game
        const lastPlayed = localStorage.getItem('lastWeeklyGame')
        if (lastPlayed !== weeklyGame.startDate.toISOString()) {
          toast.success('New weekly challenge available! 🎮', {
            duration: 5000,
            icon: '🎯'
          })
          localStorage.setItem('lastWeeklyGame', weeklyGame.startDate.toISOString())
        }
      } else {
        set({ weeklyWord: null, weeklyVariant: null })
      }
    } catch (error) {
      console.error('Error checking weekly game:', error)
      set({ weeklyWord: null, weeklyVariant: null })
    }
  },
}));

export async function createWeeklyGame(word: string, variant: GameVariant) {
  const now = new Date()
  const startOfWeek = new Date(now)
  startOfWeek.setHours(0, 0, 0, 0)
  startOfWeek.setDate(now.getDate() - now.getDay())

  const endOfWeek = new Date(startOfWeek)
  endOfWeek.setDate(startOfWeek.getDate() + 6)
  endOfWeek.setHours(23, 59, 59, 999)

  await addDoc(collection(db, 'weeklyGames'), {
    word,
    variant,
    startDate: startOfWeek,
    endDate: endOfWeek,
    createdAt: serverTimestamp()
  })
}