import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { auth, db } from '../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { X, Sparkles, CheckCircle2 } from 'lucide-react';
import toast from 'react-hot-toast';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';

interface AuthDialogProps {
  isOpen: boolean;
  onClose: () => void;
  initialMode?: 'signin' | 'signup';
}

const welcomeJokes = [
  "Welcome back, %NAME%! Ready to deduct some letters? 📊",
  "Hey %NAME%! Time to file your daily Taxle return! ✨",
  "Welcome %NAME%! Your tax expertise is non-deductible, but your skills are appreciated! 💫",
  "Great to see you, %NAME%! Welcome to the only tax game with zero penalties! 🎯",
  "Welcome back %NAME%! Let's audit some words together! No receipts needed! 📝",
  "%NAME% is back! Your word-solving skills are officially tax-exempt! 🌟",
  "Welcome %NAME% to your favorite tax shelter - where fun is always compliant! 🎉",
  "Hey %NAME%! Your Taxle gains are about to be capital! 💎",
  "Welcome back %NAME%! Ready to file your daily word return? Let's go! 🚀",
  "%NAME% has arrived! No extensions needed - you're right on time! ⭐"
];

const newUserMessages = [
  "Welcome to Taxle, %NAME%! Your tax word adventure begins now! 🎉",
  "Thanks for joining, %NAME%! Ready to put your tax knowledge to the test? ✨",
  "Welcome aboard, %NAME%! Let's make tax words fun together! 🌟",
  "Great to have you, %NAME%! Time to decode the language of tax! 🚀",
  "Welcome to the team, %NAME%! Your tax word journey starts here! 💫"
];

const getRandomMessage = (messages: string[], name: string) => {
  const message = messages[Math.floor(Math.random() * messages.length)];
  return message.replace(/%NAME%/g, name);
};

export function AuthDialog({ isOpen, onClose, initialMode = 'signin' }: AuthDialogProps) {
  const [isLogin, setIsLogin] = useState(initialMode === 'signin');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setShowWelcome(true)
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (!isOpen) {
      setEmail('');
      setPassword('');
      setIsLoading(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let userCredential;
      const isNewUser = !isLogin;
      
      if (isLogin) {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
      } else {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
      }

      if (userCredential.user) {
        try {
          const userDocRef = doc(db, 'users', userCredential.user.uid);
          const displayName = email.split('@')[0];
          
          await setDoc(userDocRef, {
            email: email,
            isAdmin: email === 'gwatts@engineeredtaxservices.com',
            displayName: displayName,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
          }, { merge: true });

          // Clear the form immediately
          setEmail('');
          setPassword('');
          setIsLoading(false);
          
          // Show welcome message and handle closing
          setShowWelcome(true);
          setTimeout(() => {
            setShowWelcome(false);
            onClose();
          }, 6000);

        } catch (firestoreError) {
          console.error('Firestore error:', firestoreError);
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error.code === 'auth/network-request-failed') {
        toast.error('Network error. Please check your internet connection.');
      } else if (error.code === 'auth/invalid-credential') {
        toast.error('Invalid email or password');
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleWelcomeClose = () => {
    setShowWelcome(false);
    onClose();
  };

  const handleAuthClose = () => {
    if (!auth.currentUser) {
      onClose();
    } else {
      setShowWelcome(false);
    }
  };

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      toast.success('Password reset email sent! Please check your inbox.');
      setShowForgotPassword(false);
    } catch (error: any) {
      console.error('Password reset error:', error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return createPortal(
    <>
      {isOpen && (
        <>
          {!showWelcome && (
            <div className="fixed inset-0 z-[1001] flex items-center justify-center p-4">
              <div 
                className="fixed inset-0 bg-black/90" 
                onClick={handleAuthClose}
              />
              <div className="relative bg-gray-900 w-full max-w-sm rounded-lg overflow-hidden shadow-2xl border-2 border-emerald-500 animate-content-show">
                <div className="p-8">
                  <button
                    onClick={handleAuthClose}
                    className="absolute top-6 right-6 text-gray-400 hover:text-white p-2 rounded-full hover:bg-gray-800/50 transition-colors"
                  >
                    <X size={20} />
                  </button>

                  <h2 className="text-2xl font-bold mb-6 text-white">
                    {isLogin ? 'Sign In' : 'Create Account'}
                  </h2>
                  
                  {showForgotPassword ? (
                    <form onSubmit={handlePasswordReset} className="space-y-6">
                      <h2 className="text-2xl font-bold text-white mb-6">Reset Password</h2>
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-300">
                          Email
                        </label>
                        <input
                          type="email"
                          value={resetEmail}
                          onChange={(e) => setResetEmail(e.target.value)}
                          className="w-full p-3 rounded-lg bg-gray-700 text-white border border-gray-600 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500"
                          disabled={isLoading}
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="w-full bg-emerald-600 text-white py-3 px-4 rounded-lg hover:bg-emerald-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed text-base font-medium"
                        disabled={isLoading}
                      >
                        {isLoading ? 'Sending...' : 'Send Reset Link'}
                      </button>
                      <button
                        type="button"
                        onClick={() => setShowForgotPassword(false)}
                        className="w-full text-center text-emerald-400 hover:text-emerald-300 text-sm font-medium mt-4"
                      >
                        Back to Sign In
                      </button>
                    </form>
                  ) : (
                    <form onSubmit={handleAuth} className="space-y-6">
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-300">
                          Email
                        </label>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="w-full p-3 rounded-lg bg-gray-700 text-white border border-gray-600 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500"
                          disabled={isLoading}
                          required
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-300">
                          Password
                        </label>
                        <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="w-full p-3 rounded-lg bg-gray-700 text-white border border-gray-600 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500"
                          disabled={isLoading}
                          required
                        />
                      </div>
                      {isLogin && (
                        <button
                          type="button"
                          onClick={() => setShowForgotPassword(true)}
                          className="text-sm text-emerald-400 hover:text-emerald-300 -mt-4 block"
                        >
                          Forgot your password?
                        </button>
                      )}
                      <button
                        type="submit"
                        className="w-full bg-emerald-600 text-white py-3 px-4 rounded-lg hover:bg-emerald-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed text-base font-medium"
                        disabled={isLoading}
                      >
                        {isLoading ? 'Please wait...' : (isLogin ? 'Sign In' : 'Create Account')}
                      </button>
                    </form>
                  )}
                  
                  <div className="mt-8 pt-6 border-t border-gray-800">
                    <button
                      onClick={() => setIsLogin(!isLogin)}
                      className="w-full text-center text-emerald-400 hover:text-emerald-300 text-sm font-medium"
                      disabled={isLoading}
                    >
                      {isLogin ? 'Need an account? Sign up' : 'Already have an account? Sign in'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showWelcome && (
            <div className="fixed inset-0 z-[1002] flex items-center justify-center p-4">
              <div 
                className="fixed inset-0 bg-black/75" 
                onClick={handleWelcomeClose}
              />
              <div 
                className="relative bg-gray-900 p-8 rounded-2xl max-w-md w-full shadow-2xl animate-float-in" 
                onClick={(e) => e.stopPropagation()}
              >
                <div className="absolute inset-0 bg-gradient-to-br from-emerald-500/10 to-emerald-600/10 rounded-2xl" />
                <div className="relative flex items-center gap-6">
                  <div className="flex-shrink-0">
                    <div className="w-16 h-16 rounded-full bg-gradient-to-br from-emerald-400 to-emerald-600 flex items-center justify-center animate-bounce shadow-lg">
                      <CheckCircle2 className="w-10 h-10 text-white" />
                    </div>
                  </div>
                  <div>
                    <h3 className="text-2xl font-bold text-white mb-3 flex items-center gap-2">
                      {isLogin ? 'Welcome Back!' : 'Welcome to Taxle!'}
                      <Sparkles className="w-6 h-6 text-yellow-300 animate-spin-slow" />
                    </h3>
                    <p className="text-lg text-emerald-50 leading-relaxed">
                      {getRandomMessage(
                        isLogin ? welcomeJokes : newUserMessages,
                        auth.currentUser?.displayName || 
                        auth.currentUser?.email?.split('@')[0] || 
                        'Tax Master'
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>,
    document.body
  )
}

export default AuthDialog;