import React, { useState, useEffect } from 'react'
import { collection, query, getDocs, addDoc, updateDoc, deleteDoc, doc, Timestamp, where } from 'firebase/firestore'
import { db } from '../../firebase'
import { TournamentForm } from './TournamentForm'
import { Trophy, Users, Calendar, Award, Clock, Edit, Trash2, Plus, X } from 'lucide-react'
import toast from 'react-hot-toast'
import { Tournament, TournamentTeam, TeamMember, UserData, TournamentFormData } from '../../types'
import { TeamManagement } from './TeamManagement'

interface TournamentFormState extends Omit<TournamentFormData, 'startDate' | 'endDate' | 'registrationDeadline'> {
  id?: string;
  startDate: Date;
  endDate: Date;
  registrationDeadline: Date;
}

const formatTimestamp = (timestamp: any): Date => {
  if (timestamp instanceof Timestamp) {
    return timestamp.toDate();
  }
  if (typeof timestamp === 'object' && timestamp?.seconds && timestamp?.nanoseconds) {
    return new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
  }
  return new Date();
};

export function TournamentManagement() {
  const [tournaments, setTournaments] = useState<Tournament[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [showForm, setShowForm] = useState(false)
  const [editingTournament, setEditingTournament] = useState<TournamentFormState | undefined>()
  const [selectedTournament, setSelectedTournament] = useState<Tournament | null>(null)

  useEffect(() => {
    loadTournaments()
  }, [])

  const loadTournaments = async () => {
    setIsLoading(true)
    try {
      const tournamentsRef = collection(db, 'tournaments')
      const q = query(tournamentsRef)
      const snapshot = await getDocs(q)
      const tournamentsData = snapshot.docs.map(doc => {
        const data = doc.data()
        // Ensure all timestamps are properly converted to Timestamp objects
        return {
          id: doc.id,
          ...data,
          startDate: data.startDate instanceof Timestamp ? data.startDate : Timestamp.fromMillis(data.startDate.seconds * 1000),
          endDate: data.endDate instanceof Timestamp ? data.endDate : Timestamp.fromMillis(data.endDate.seconds * 1000),
          registrationDeadline: data.registrationDeadline instanceof Timestamp ? data.registrationDeadline : Timestamp.fromMillis(data.registrationDeadline.seconds * 1000),
          createdAt: data.createdAt instanceof Timestamp ? data.createdAt : Timestamp.fromMillis(data.createdAt.seconds * 1000),
          updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt : Timestamp.fromMillis(data.updatedAt.seconds * 1000),
          teams: data.teams || [],
          participants: data.participants || [],
          rules: data.rules || [],
          prizes: {
            first: data.prizes?.first || '',
            second: data.prizes?.second,
            third: data.prizes?.third
          }
        } as Tournament;
      });
      setTournaments(tournamentsData);
    } catch (error) {
      console.error('Error loading tournaments:', error);
      toast.error('Failed to load tournaments');
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (timestamp: Timestamp | null | undefined): string => {
    if (!timestamp) return 'N/A';
    try {
      if (timestamp instanceof Timestamp) {
        return timestamp.toDate().toLocaleDateString() + ' ' + timestamp.toDate().toLocaleTimeString();
      }
      // Handle Firebase timestamp object with type assertion
      if (typeof timestamp === 'object' && timestamp && 'seconds' in timestamp && 'nanoseconds' in timestamp) {
        const firestampData = timestamp as { seconds: number; nanoseconds: number };
        const date = new Timestamp(
          firestampData.seconds,
          firestampData.nanoseconds
        ).toDate();
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
      }
      return 'Invalid Date';
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  const handleSubmit = async (data: TournamentFormData) => {
    try {
      const tournamentData = {
        name: data.name,
        description: data.description,
        startDate: Timestamp.fromDate(data.startDate),
        endDate: Timestamp.fromDate(data.endDate),
        registrationDeadline: Timestamp.fromDate(data.registrationDeadline),
        maxTeams: data.maxTeams,
        teamSize: data.teamSize,
        prizes: {
          first: data.prizes.first || '',
          second: data.prizes.second,
          third: data.prizes.third
        },
        rules: data.rules || [],
        teams: editingTournament?.id 
          ? tournaments.find(t => t.id === editingTournament.id)?.teams || [] 
          : [],
        participants: editingTournament?.id 
          ? tournaments.find(t => t.id === editingTournament.id)?.participants || [] 
          : [],
        updatedAt: Timestamp.now()
      };

      if (editingTournament?.id) {
        const docRef = doc(db, 'tournaments', editingTournament.id);
        await updateDoc(docRef, tournamentData);
        toast.success('Tournament updated successfully');
      } else {
        const collectionRef = collection(db, 'tournaments');
        await addDoc(collectionRef, {
          ...tournamentData,
          createdAt: Timestamp.now()
        });
        toast.success('Tournament created successfully');
      }

      setShowForm(false);
      setEditingTournament(undefined);
      loadTournaments();
    } catch (error) {
      console.error('Error submitting tournament:', error);
      toast.error('Failed to save tournament');
    }
  };

  const handleDelete = async (tournamentId: string) => {
    if (!confirm('Are you sure you want to delete this tournament?')) return

    try {
      await deleteDoc(doc(db, 'tournaments', tournamentId))
      toast.success('Tournament deleted successfully')
      loadTournaments()
    } catch (error) {
      console.error('Error deleting tournament:', error)
      toast.error('Failed to delete tournament')
    }
  }

  const handleEdit = (tournament: Tournament) => {
    const formState: TournamentFormState = {
      id: tournament.id,
      name: tournament.name,
      description: tournament.description,
      startDate: tournament.startDate.toDate(),
      endDate: tournament.endDate.toDate(),
      registrationDeadline: tournament.registrationDeadline.toDate(),
      maxTeams: tournament.maxTeams,
      teamSize: tournament.teamSize,
      prizes: {
        first: tournament.prizes.first,
        second: tournament.prizes.second,
        third: tournament.prizes.third
      },
      rules: tournament.rules,
      teamNames: tournament.teams.map(t => t.name),
      companyOnly: false,
      notificationsEnabled: false
    };
    setEditingTournament(formState);
  };

  if (showForm) {
    return (
      <div className="fixed inset-0 z-50 overflow-hidden">
        {/* Backdrop */}
        <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={() => {
          if (!editingTournament || confirm('Discard changes?')) {
            setShowForm(false);
            setEditingTournament(undefined);
          }
        }} />
        
        {/* Dialog */}
        <div className="absolute inset-4 md:inset-8 flex items-start justify-center overflow-hidden">
          <div className="relative bg-white dark:bg-gray-900 w-full max-w-4xl rounded-lg shadow-xl flex flex-col max-h-full">
            {/* Header */}
            <div className="flex items-center justify-between p-4 border-b dark:border-gray-700 bg-white dark:bg-gray-900 sticky top-0 z-10">
              <h2 className="text-xl font-bold dark:text-white flex items-center gap-2">
                <Trophy className="text-blue-500" />
                {editingTournament ? 'Edit Tournament' : 'Create Tournament'}
              </h2>
              <button
                onClick={() => {
                  if (!editingTournament || confirm('Discard changes?')) {
                    setShowForm(false);
                    setEditingTournament(undefined);
                  }
                }}
                className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800"
              >
                <X size={24} />
              </button>
            </div>

            {/* Scrollable Form Content */}
            <div className="flex-1 overflow-y-auto">
              <div className="p-6">
                <TournamentForm
                  onSubmit={handleSubmit}
                  initialData={editingTournament}
                  isEditing={!!editingTournament}
                  onCancel={() => {
                    if (!editingTournament || confirm('Discard changes?')) {
                      setShowForm(false);
                      setEditingTournament(undefined);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (selectedTournament) {
    return (
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold">{selectedTournament.name}</h2>
          <button
            onClick={() => setSelectedTournament(null)}
            className="text-gray-500 hover:text-gray-700"
          >
            Back
          </button>
        </div>
        <TeamManagement
          tournamentId={selectedTournament.id}
          tournament={selectedTournament}
          onUpdate={loadTournaments}
        />
      </div>
    )
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-end">
        <button
          onClick={() => setShowForm(true)}
          className="flex items-center gap-2 px-4 py-2 bg-accent-500 text-white rounded-lg hover:bg-accent-600 transition-colors"
        >
          <Plus size={20} />
          Create Tournament
        </button>
      </div>

      {isLoading ? (
        <div className="text-center py-4 text-surface-200">Loading tournaments...</div>
      ) : tournaments.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-surface-200 text-lg">
            No tournaments found. Create one to get started!
          </p>
        </div>
      ) : (
        <div className="grid gap-4">
          {tournaments.map(tournament => (
            <div
              key={tournament.id}
              className="bg-surface-800 rounded-lg p-6 space-y-4 border border-surface-700"
            >
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-semibold text-white">{tournament.name}</h3>
                  <p className="text-surface-300">
                    {tournament.description}
                  </p>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => setSelectedTournament(tournament)}
                    className="p-2 text-accent-400 hover:bg-surface-700 rounded-lg transition-colors"
                  >
                    <Users size={20} />
                  </button>
                  <button
                    onClick={() => {
                      handleEdit(tournament);
                      setShowForm(true);
                    }}
                    className="p-2 text-accent-400 hover:bg-surface-700 rounded-lg transition-colors"
                  >
                    <Edit size={20} />
                  </button>
                  <button
                    onClick={() => handleDelete(tournament.id)}
                    className="p-2 text-red-500 hover:bg-red-50 rounded-lg"
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="flex items-center gap-2 text-sm">
                  <Calendar className="text-gray-400" size={16} />
                  <span>Starts: {formatDate(tournament.startDate)}</span>
                </div>
                <div className="flex items-center gap-2 text-sm">
                  <Clock className="text-gray-400" size={16} />
                  <span>Ends: {formatDate(tournament.endDate)}</span>
                </div>
                <div className="flex items-center gap-2 text-sm">
                  <Users className="text-gray-400" size={16} />
                  <span>
                    Teams: {tournament.teams?.length || 0} / {tournament.maxTeams}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-2 text-sm">
                <Award className="text-gray-400" size={16} />
                <span>
                  1st Place: {tournament.prizes.first}
                  {tournament.prizes.second && ` • 2nd: ${tournament.prizes.second}`}
                  {tournament.prizes.third && ` • 3rd: ${tournament.prizes.third}`}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
} 