import React, { useEffect } from 'react';
import { useStore } from '../store';
import type { KeyboardKey } from '../types';
import { gameSounds } from '../utils/sounds';

const KEYBOARD_ROWS = [
  ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
  ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
  ['ENTER', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '⌫'],
];

export default function Keyboard() {
  const { addLetter, removeLetter, addGuess, currentGuess, guesses, solution } = useStore();

  // Handle physical keyboard input
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      // Get the active element
      const activeElement = document.activeElement;
      
      // If we're in an input, textarea, or contentEditable element, don't handle game keys
      if (
        activeElement instanceof HTMLInputElement ||
        activeElement instanceof HTMLTextAreaElement ||
        activeElement?.hasAttribute('contenteditable') ||
        activeElement?.closest('form') // Also check if we're anywhere inside a form
      ) {
        return;
      }

      // Ignore command/ctrl key combinations
      if (event.metaKey || event.ctrlKey) return;
      
      const key = event.key.toLowerCase();

      if (key === 'enter') {
        event.preventDefault();
        if (currentGuess.length === 5) {
          gameSounds.play('flip');
          addGuess(currentGuess);
        } else {
          gameSounds.play('error');
        }
      } else if (key === 'backspace' || key === 'delete') {
        event.preventDefault();
        gameSounds.play('pop');
        removeLetter();
      } else if (/^[a-z]$/.test(key)) {
        event.preventDefault();
        if (currentGuess.length < 5) {
          gameSounds.play('type');
          addLetter(key.toUpperCase());
        } else {
          gameSounds.play('error');
        }
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  }, [addLetter, removeLetter, addGuess, currentGuess]);

  // Mobile keyboard support
  useEffect(() => {
    let input: HTMLInputElement | null = null;
    
    // Only create hidden input if we're in the game area
    const gameArea = document.querySelector('.game-area');
    if (!gameArea) return;

    const createInput = () => {
      // Don't create input if we're in a form
      if (document.activeElement?.closest('form')) return null;
      
      const newInput = document.createElement('input');
      newInput.type = 'text';
      newInput.autocomplete = 'off';
      newInput.autocapitalize = 'off';
      newInput.inputMode = 'text';
      newInput.style.cssText = `
        position: fixed;
        opacity: 0;
        pointer-events: none;
        left: -1000px;
        top: -1000px;
      `;
      document.body.appendChild(newInput);

      // Add input handler
      newInput.addEventListener('input', handleInput);
      return newInput;
    };

    const handleInput = (event: Event) => {
      const inputElement = event.target as HTMLInputElement;
      const value = inputElement.value.toLowerCase();
      
      if (value && !document.activeElement?.closest('form')) {
        const lastChar = value[value.length - 1];
        if (/^[a-z]$/.test(lastChar)) {
          if (currentGuess.length < 5) {
            gameSounds.play('type');
            addLetter(lastChar.toUpperCase());
          } else {
            gameSounds.play('error');
          }
        }
      }
      inputElement.value = '';
    };

    const handleGameAreaClick = (e: Event) => {
      // Don't handle if clicking on form elements
      const target = e.target as HTMLElement;
      if (target.closest('form')) return;
      
      // Create input if it doesn't exist
      if (!input) {
        input = createInput();
      }
      
      // Focus the input if it exists
      input?.focus();
    };

    // Add properly typed event listener
    gameArea.addEventListener('click', handleGameAreaClick as EventListener);

    return () => {
      gameArea.removeEventListener('click', handleGameAreaClick as EventListener);
      if (input) {
        input.removeEventListener('input', handleInput);
        input.remove();
      }
    };
  }, [addLetter, currentGuess.length]);

  const getKeyStatus = (key: string): KeyboardKey['status'] => {
    const guessedLetters = guesses.join('');
    if (!guessedLetters.includes(key)) return 'unused';
    if (solution.includes(key)) {
      if ([...guesses.join('')].some((letter, i) => letter === key && solution[i % 5] === key)) {
        return 'correct';
      }
      return 'present';
    }
    return 'absent';
  };

  const handleKeyClick = (key: string) => {
    if (gameSounds.isEnabled()) {
      gameSounds.play('click')
    }
    
    if (key === 'ENTER' && currentGuess.length === 5) {
      gameSounds.play('flip')
      addGuess(currentGuess)
    } else if (key === '⌫') {
      gameSounds.play('pop')
      removeLetter()
    } else if (key.length === 1 && currentGuess.length < 5) {
      gameSounds.play('type')
      addLetter(key)
    } else if (currentGuess.length >= 5) {
      gameSounds.play('error')
    }
  };

  const getKeyClasses = (status: KeyboardKey['status']) => {
    const base = "rounded font-bold transition-all duration-150 text-sm sm:text-base active:scale-95 touch-manipulation";
    const sizes = "h-12 sm:h-14 min-w-[2rem] sm:min-w-[2.75rem]";
    const colors = {
      unused: "bg-gray-700 text-white hover:bg-gray-600",
      correct: "bg-emerald-500 text-white",
      present: "bg-amber-500 text-white",
      absent: "bg-gray-600 text-white",
    };
    return `${base} ${sizes} ${colors[status]}`;
  };

  return (
    <div className="w-full max-w-2xl mx-auto px-1 sm:px-2">
      {KEYBOARD_ROWS.map((row, i) => (
        <div key={i} className="flex justify-center gap-0.5 sm:gap-1 my-1">
          {row.map((key) => {
            const status = key.length === 1 ? getKeyStatus(key) : 'unused';
            return (
              <button
                key={key}
                onClick={() => handleKeyClick(key)}
                className={getKeyClasses(status)}
                style={{ flex: key === 'ENTER' ? '1.5' : key === '⌫' ? '1.5' : '1' }}
              >
                {key}
              </button>
            );
          })}
        </div>
      ))}
    </div>
  );
}