import React from 'react';
import { Trophy, Medal, Crown, Users } from 'lucide-react';
import { Tournament } from '../types';

export interface ScoreboardProps {
  data: ScoreData[];
  title: string;
  tournament?: Tournament;
}

interface ScoreData {
  name: string;
  score: number;
  photoURL?: string | null;
  isCurrentUser?: boolean;
  metadata?: {
    company?: string | null;
    department?: string | null;
    winRate?: string;
    bestStreak?: number;
    averageGuesses?: string;
    memberCount?: number;
    rank?: number;
    gamesPlayed?: number;
    gamesWon?: number;
    totalGames?: number;
  };
}

// Helper function to safely format numbers
const formatNumber = (value: number | undefined | null): string => {
  if (typeof value !== 'number') return '0';
  return value.toString();
};

function Scoreboard({ data, title, tournament }: ScoreboardProps) {
  const formatMetadata = (metadata: ScoreData['metadata']) => {
    if (!metadata) return null;

    if (tournament) {
      return metadata.memberCount ? (
        <span className="flex items-center gap-1">
          <Users size={14} />
          {metadata.memberCount} members
        </span>
      ) : null;
    }

    // For regular leaderboard, only show company/department if they exist
    const parts = [];
    if (metadata.company && metadata.company !== 'Unknown') {
      parts.push(metadata.company);
    }
    if (metadata.department && metadata.department !== 'Unknown') {
      parts.push(metadata.department);
    }

    return parts.length > 0 ? parts.join(' • ') : null;
  };

  const formatWinRate = (metadata: ScoreData['metadata']) => {
    if (!metadata) return null;
    
    if (tournament) {
      return (
        <>
          {`Rank: ${metadata.rank || '-'}`}
          {metadata.gamesPlayed ? ` • ${metadata.gamesPlayed} Games` : ''}
        </>
      );
    }

    // For regular leaderboard
    const winRate = metadata.winRate ? parseFloat(metadata.winRate) : 0;
    const winRateDisplay = winRate > 0 ? `Win Rate: ${winRate.toFixed(1)}%` : null;
    
    const statsDisplay = [];
    
    if (metadata.gamesWon && metadata.gamesWon > 0) {
      statsDisplay.push(`${metadata.gamesWon} Wins`);
    }
    if (metadata.bestStreak && metadata.bestStreak > 0) {
      statsDisplay.push(`Best Streak: ${metadata.bestStreak}`);
    }

    return (
      <>
        {winRateDisplay}
        {winRateDisplay && statsDisplay.length > 0 && ' • '}
        {statsDisplay.join(' • ')}
      </>
    );
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold text-white">{title}</h3>
      <div className="space-y-2">
        {data.map((item, index) => (
          <div
            key={`${item.name}-${index}`}
            className={`bg-gray-700 rounded-lg p-4 ${
              item.isCurrentUser ? 'ring-2 ring-emerald-500' : ''
            }`}
          >
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-3">
                <div className="flex-shrink-0">
                  {index === 0 ? (
                    <Trophy className="text-yellow-400" size={24} />
                  ) : index === 1 ? (
                    <Medal className="text-gray-400" size={24} />
                  ) : index === 2 ? (
                    <Medal className="text-amber-600" size={24} />
                  ) : (
                    <div className="w-6 h-6 flex items-center justify-center text-gray-400">
                      {index + 1}
                    </div>
                  )}
                </div>
                <div>
                  <div className="flex items-center space-x-2">
                    {item.photoURL && (
                      <img
                        src={item.photoURL}
                        alt={item.name}
                        className="w-6 h-6 rounded-full"
                      />
                    )}
                    <span className="text-white font-medium">{item.name}</span>
                  </div>
                  {item.metadata && (
                    <div className="text-sm text-gray-400">
                      {formatMetadata(item.metadata)}
                    </div>
                  )}
                </div>
              </div>
              <div className="text-right">
                <div className="text-2xl font-bold text-emerald-400">
                  {item.score}
                  {tournament ? ' pts' : '%'}
                </div>
                {item.metadata && (
                  <div className="text-sm text-gray-400">
                    {formatWinRate(item.metadata)}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Scoreboard;